<template>
  <div class="Login">
    <img src="@/assets/img/login-img/loginback.png" alt="" class="login-back" />
    <div class="login-box">
      <div class="title">药聚力平台管理系统</div>
      <div class="login-message">
        <div class="login-title">登录</div>
        <!-- 账号 -->
        <div class="login-name">账号</div>
        <div class="login-input">
          <!-- <div class="left-ico">
            <img src="@/assets/img/login-img/username.png" alt="" />
          </div> -->
          <div class="centen-input">
            <input
              @change="changeName(userLogin.userName)"
              v-model.trim="userLogin.userName"
              type="text"
              class="inpu-1"
              :tabindex="1"
              placeholder="用户名"
              v-on:keyup.tab.prevent="tabAlter"
              @keyup.enter="toLogin"
            />
          </div>
        </div>
        <!-- 密码 -->
        <div class="login-name">密码</div>
        <div class="login-input">
          <!-- <div class="left-ico">
            <img src="@/assets/img/login-img/password.png" alt="" />
          </div> -->
          <div class="centen-input">
            <input
              @change="changePassword(userLogin.userPwd)"
              @keyup.enter="toLogin"
              v-model.trim="userLogin.userPwd"
              :type="!isPassword ? 'password' : 'text'"
              :tabindex="2"
              class="inpu-1"
              placeholder="密码"
            />
          </div>
          <div class="left-ico right-ico" @click="isPassword = !isPassword">
            <img
              v-if="isPassword"
              src="@/assets/img/login-img/eye.png"
              alt=""
            />
            <img
              v-if="!isPassword"
              src="@/assets/img/login-img/noeye.png"
              alt=""
            />
          </div>
        </div>
        <!-- 错误信息提示 -->
        <div class="error" v-if="ishint">请输入账号密码</div>
        <!-- 记住密码 -->
        <div class="bearPassWord">
          <el-checkbox v-model="checked">记住密码</el-checkbox>
        </div>
        <!-- 登录 -->
        <div class="login-btn">
          <div class="btn" @click="toLogin">登录</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setToken } from "@/utils/auth";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("users");
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
export default {
  data() {
    return {
      isPassword: false, //判断密码是否显示
      checked: false, //是否勾选记住密码
      ishint: false, //提示信息是否现实
      // 用户登录信息
      userLogin: {
        userName: "",
        userPwd: "",
      },
      isLogin: true,
    };
  },
  mounted() {
    // 获取记住的
    if (
      this.$cookie.get("userName") != null &&
      this.$cookie.get("userPwd") != null
    ) {
      this.userLogin = {
        userName: this.$cookie.get("userName"),
        userPwd: this.$cookie.get("userPwd"),
      };
      this.checked = true;
    } else {
      this.checked = false;
    }
  },
  methods: {
    ...mapActions(["login"]),
    ...commonIndex.mapMutations(["emptyTages", "ChangePermission"]),
    // 登录
    async toLogin() {
      // const loading = this.$loading({
      //   lock: true,
      //   text: "登陆中请稍后",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)",
      // });
      this.isLogin = false;
      if (!this.userLogin.userName || !this.userLogin.userPwd) {
        this.$message({
          message: "用户名或密码不能为空",
          type: "warning",
        });
        // loading.close();
        this.ishint = true;
        return;
      }
      let timeNew = setTimeout(() => {
        // loading.close();
        this.$message({
          message: "请联系后台管理人员",
          type: "error",
        });
      }, 10000);
      let data = await this.login(this.userLogin);
      clearTimeout(timeNew);
      // loading.close();
      if (data.code == 200) {
        this.emptyTages();
        this.ChangePermission(true);
        this.$message({
          showClose: true,
          message: data.message,
          type: "success",
        });
        if (this.checked) {
          this.$cookie.set("userName", this.userLogin.userName);
          this.$cookie.set("userPwd", this.userLogin.userPwd);
        } else {
          this.$cookie.delete("userName");
          this.$cookie.delete("userPwd");
        }
        this.$cookie.set("newUserName", this.userLogin.userName);
        //
        this.$cookie.set("role", data.data.role);
        sessionStorage.setItem("admin_id", data.data.admin_id);
        // sessionStorage.setItem("TOKEN", data.data.token);
        setToken(data.data.token);
        this.$router.push({ path: "/Home" });
      } else {
        this.$message({
          message: data.message,
          type: "error",
        });
      }
    },
    //换行
    tabAlter(e) {
      //console.log(e);
    },
    // 账号
    changeName(data) {
      if (!data) return (this.ishint = true);
      this.ishint = false;
    },
    // 密码
    changePassword(data) {
      if (!data) return (this.ishint = true);
      this.ishint = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.el-checkbox__label {
  color: #fff;
}
</style>
<style lang="scss" scoped>
.Login {
  width: 100vw;
  // min-width: 600px;
  min-height: 600px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .login-back {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
  }
  .login-box {
    // width: 480px;
    // // max-width: 500px;
    // // max-height: 600px;
    // height: 520px;
    .login-message {
      background-color: #fff;
      padding: 43px 53px;
      margin-top: 37px;
      border-radius: 4px;
      width: 480px;
      height: 520px;
      box-sizing: border-box;
      .login-title {
        font-weight: bold;
        font-size: 28px;
        color: #333;
        margin-bottom: 51px;
      }
      .login-name {
        font-size: 14px;
        color: #666;
        margin-bottom: 10px;
      }
    }
    .title {
      text-align: center;
      width: 100%;
      height: 40px;
      color: #0189ff;
      font-size: 40px;
      font-weight: bold;
    }
    // 账号密码框
    .login-input {
      width: 100%;
      height: 40px;
      background: #fff;
      border-bottom: 1px solid #e7e7e7;
      margin-bottom: 36px;
      display: flex;
      .right-ico {
        cursor: pointer;
      }
      .left-ico {
        width: 10%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 20px;
          height: 20px;
        }
      }
      .centen-input {
        // width: 80%;
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        input:-webkit-autofill {
          transition: background-color 5000s ease-in-out 0s;
          color: #fff;
        }
        // input {
        //   appearance: none;
        // }
        .inpu-1 {
          width: 100%;
          height: 80%;
          background: none;
          border: none;
          outline: none;
          font-size: 16px;
          font-weight: bold;
          color: #333;
        }
        ::-webkit-input-placeholder {
          /* WebKit browsers，webkit内核浏览器 */
          color: #b6b6b6;
          font-size: 16px;
          font-weight: normal;
        }
      }
    }
    .error {
      width: 100%;
      color: #ec4a5e;
    }
    // 记住密码
    .bearPassWord {
      margin-top: 20px;
      width: 100%;
      height: 40px;
      color: #999;
      .el-checkbox__label {
        color: #999;
      }
    }
    // 登录按钮
    .login-btn {
      margin-top: 10px;
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      color: #fff;
      font-weight: bold;
      background: #1890ff;
      border-radius: 2px;
      cursor: pointer;
    }
  }
}
::v-deep .el-checkbox__label {
  color: #999;
}
</style>
