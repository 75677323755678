//引入common.js的方法
import AxiosPpst from './componentspost'

export function PostAxios(url, data) {
    return new Promise((resolve, reject) => {
        //成功处理
        AxiosPpst(url, data).then(res => resolve(res))
            //失败抛出错误
            .catch(error => console.log(error))
    })
}