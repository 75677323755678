import { GetAxios } from "../../../api/AxiosGet";//get请求
import { PostAxios } from "../../../api/AxiosPost"//post请求
import { login, index, resetPassword } from "../../../api/phpUrl"//引入接口
import { Message } from 'element-ui'//引入elmentui提示框
export default {
  namespaced: true,
  // 集中数据
  state: {
    numbve: true,
  },
  // 处理state中的数据
  mutations: {
    SET_NUMBVE: (state, numbve) => {
      state.numbve = numbve
    },
  },
  // 发送异步请求
  actions: {
    // 登录
    async login(context, params) {
      return await PostAxios(login, params)
    },
    // 首页
    async getindex(context, params) {
      return await PostAxios(index, params)
    },
    // 修改密码
    async postresetPassword(context, params) {
      return await PostAxios(resetPassword, params)
    },
    changenumbve({ commit }, numbve) {
      commit('SET_NUMBVE', numbve)
    },
  },
}