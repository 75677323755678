import { GetAxios } from "../../../api/AxiosGet"; //get请求
import { PostAxios } from "../../../api/AxiosPost"; //post请求
import {
  getAlarm, //获取预警配置
  setAlarm, //设置预警配置
  reviewList, //供应商审核列表
  reviewInfo, //供应商审核详情
  qualificationExamineList, //往期资质
  memberAuditlist, //会员审核列表
  // memberAudituserDetail, //会员基础资料
  // memberAudituserqualify, //会员资质详情
  reviewstep, //供应商审核步骤
  // memberAuditaudit, //会员审核详情
  // memberAuditstatusLine, //会员资质审核时间线
  // memberAuditunit, //单位信息详情
  // memberAuditaddressMsg, //收货信息
  // memberAuditsavesLine, //更新时间线
  // memberAuditscopeBusiness, //会员经营范围详情
  // memberAuditsaveScopeBusiness, //保存经营范围
  v2memberAuditsaveScopeBusiness, //保存经营范围
  // memberAuditsavesaveUserInfo, //保存会员信息
  reviewchangeSelect, //设置经营范围
  scopeConfig, //新设置经营范围
  reviewsetLoan, //设置白条分佣
  reviewsetDistrict, //设置区域
  memberAuditsavelist, //商品审核
  platformGoodsis_audit, //审核资料库
  reviewgetDownLoad, //下载所有资料
  v2download,
  v2downloadAudit,
  goodsAuditdistrict, //获取销售商圈
  memberAuditqualifyDown, //下载会员资质
  downloadReview, //会员审核资质下载
  activityReview, //审核活动商品
  userTypeList,
  activityReviewList,//审核活动商品列表
  activityReviewInfo,//审核商品详情
  UserQualificationMemberAuditReviewList,//审核会员列表
  memberAuditUserInfo,//会员信息详情
  memberAuditReviewUserInfo,//提交会员审核.
  logList,
  afficheList,
  afficheDetail,
  afficheAudit,
  refreshAuditStatus,
  getQualifyEffective,
  getscopeBusiness,
  savesLine,//审核时间线  首营版本JAVA转PHP
  saveUserInfo,//保存会员信息 首营版本JAVA转PHP
} from "../../../api/phpUrl"; //引入接口
import {
  memberAuditstatusLine, //会员资质审核时间线
  memberAudituserDetail, //会员基础资料
  memberAudituserqualify, //会员资质详情
  memberAuditaudit, //会员审核详情
  memberAuditsavesLine, //更新时间线
  memberAuditunit, //单位信息详情
  memberAuditaddressMsg, //收货信息
  memberAuditsavesaveUserInfo, //保存会员信息
  memberAuditscopeBusiness, //会员经营范围详情
  memberAuditsaveScopeBusiness, //保存经营范围
  withdrawList,         //佣金审核提现列表
  withdrawDetailList,   // 明细
  withdrawUpdateStatus,  //审核通过或审核驳回
  queryWithdrawMoney,  //查询列表佣金总金额
  withdListExport // 导出
} from "../../../api/javaUrl"; //引入接口
import router from "@/router";
import { Message } from "element-ui"; //引入elmentui提示框
export default {
  namespaced: true,
  // 集中数据
  state: {
    reviewInfoData: {
      district: [], //地区
      scope: [], //经营类别
      info: {
        content: {
          qualification: [],
        },
      }, //基本信息
    },
    stepValue: {
      step: 0,
    },
    UserQualification: {
      qualify_detail: [],
      qualify: {
        status: null,
      },
    }, //资质审核数据
    // 会员资质步骤
    UsersValue: {
      time_line: 1,
    },
    goodsAuditPage: 1,
  },
  // 处理state中的数据
  mutations: {
    cgGoodsAuditPage(state, data) {
      state.goodsAuditPage = data;
    },
    // 存储供应商审核详情数据
    setReviewData(state, data) {
      if (JSON.stringify(data) != "{}") {
        state.stepValue.step = data.step;
        data.scope.forEach((item) => {
          if (item.expiration_time == "9999-12-31 00:00:00") {
            item.flang = true;
          } else {
            item.flang = false;
          }
        });
        state.reviewInfoData = data;
      } else {
        // router.push({ path: '/goodsAudit' });
        // Message({
        //     message: '信息有误请联系管理人员',
        //     type: 'warning'
        // })
      }
    },
    // 储存用户审核数据
    serUserData(state, data) {
      state.UsersValue = data.content;
      state.UsersValue.userTimeLine = state.UsersValue.userTimeLine || 1
    },
    // 佣金体现审核明细
    setWithdData(state, data) { },
    // 跟新资质
    updateQa(state, data) {
      data.qualify.forEach((item) => {
        item.hint = false;
        if (item.effectiveEndTime == `9999-12-31 00:00:00`) {
          item.flang = true;
        }
      });
      state.UserQualification = data;
    },
    ModifySelected(state, data) {
      if (data.business_id) {
        data.business_id = null;
      } else {
        data.business_id = data.supplierId;
      }
    },
    // 弹出警告
    popupHint(state, data) {
      state.UserQualification.qualify_detail.forEach((item) => {
        if (!item.qualify_expiry) {
          item.hint = true;
        } else {
          item.hint = false;
        }
      });
    },
  },
  // 发送异步请求
  actions: {
    async getUserType(context) {
      let { data } = await GetAxios(userTypeList);
      // context.commit("setUesrType", data);
      return data
    },
    // 供应商审核列表
    async getreviewList(context, params) {
      return await GetAxios(reviewList, params);
    },
    //供应商审核详情
    async getreviewInfo(context, params) {
      let data = await GetAxios(reviewInfo, params);
      context.commit("setReviewData", data.data);
      return data;
    },
    //往期资质
    async qualificationExamineList(context, params) {
      return await GetAxios(qualificationExamineList, params);
    },
    // 供应商审核通过下一步
    async postreviewstep(context, params) {
      return await PostAxios(reviewstep, params);
    },
    // 会员审核列表
    async postmemberAuditlist(context, params) {
      return await PostAxios(memberAuditlist, params);
    },
    // 会员基础资料
    async getmemberAudituserDetail(context, params) {
      return await GetAxios(memberAudituserDetail, params);
    },
    // 会员资质详情
    async getmemberAudituserqualify(context, params) {
      let data = await GetAxios(memberAudituserqualify, params);
      context.commit("updateQa", data.content);
      return data;
    },
    // 获取预警配置
    async getAlarm(context, params) {
      return await GetAxios(getAlarm, params);
    },
    // 设置预警配置
    async setAlarm(context, params) {
      return await PostAxios(setAlarm, params);
    },
    // 会员审核详情
    async postmemberAuditaudit(context, params) {
      return await PostAxios(memberAuditaudit, params);
    },
    // 会员审核时间线
    async postmemberAuditstatusLine(context, params) {
      let data = await GetAxios(memberAuditstatusLine, params);
      context.commit("serUserData", data);
    },
    //单位信息详情
    async getmemberAuditunit(context, params) {
      return await GetAxios(memberAuditunit, params);
    },
    // 收货信息
    async getmemberAuditaddressMsg(context, params) {
      return await GetAxios(memberAuditaddressMsg, params);
    },
    // 更新时间线
    async postmemberAuditsavesLine(context, params) {
      return await PostAxios(savesLine, params);
    },
    // 获取经营范围
    async getmemberAuditscopeBusiness(context, params) {
      return await GetAxios(getscopeBusiness, params);
    },
    // 保存经营范围
    async postmemberAuditsaveScopeBusiness(context, params) {
      return await PostAxios(memberAuditsaveScopeBusiness, params);
    },
    // 保存经营范围
    async postv2memberAuditsaveScopeBusiness(context, params) {
      return await PostAxios(v2memberAuditsaveScopeBusiness, params);
    },
    //保存会员信息
    async postmemberAuditsavesaveUserInfo(context, params) {
      return await PostAxios(saveUserInfo, params);
    },
    // 设置经营范围
    async postreviewchangeSelect(context, params) {
      return await PostAxios(reviewchangeSelect, params);
    },
    // 新设置经营范围
    async postscopeConfig(context, params) {
      return await PostAxios(scopeConfig, params);
    },
    //设置白条范围
    async postreviewsetLoan(context, params) {
      return await PostAxios(reviewsetLoan, params);
    },
    //设置区域
    async postreviewsetDistrict(context, params) {
      return await PostAxios(reviewsetDistrict, params);
    },
    // 商品审核
    async postmemberAuditsavelist(context, params) {
      return await PostAxios(memberAuditsavelist, params);
    },
    // 查看审核
    async getplatformGoodsis_audit(context, params) {
      return await GetAxios(platformGoodsis_audit, params);
    },
    // 下载资料
    async getreviewgetDownLoad(context, params) {
      return await GetAxios(reviewgetDownLoad, params);
    },
    async getv2download(context, params) {
      return await GetAxios(v2download, params);
    },
    // 下载资料
    async getv2downloadAudit(context, params) {
      return await GetAxios(v2downloadAudit, params);
    },
    // 销售时间
    async getgoodsAuditdistrict(context, params) {
      return await GetAxios(goodsAuditdistrict, params);
    },
    async getmemberAuditqualifyDown(context, params) {
      return await GetAxios(memberAuditqualifyDown, params);
    },
    async getdownloadReview(context, params) {
      return await GetAxios(downloadReview, params);
    },
    // 活动商品审核
    async postactivityReview(context, params) {
      return await PostAxios(activityReview, params);
    },
    // 审核活动商品列表
    async getactivityReviewList(context, params) {
      return await GetAxios(activityReviewList, params);
    },
    //审核商品详情
    async getactivityReviewInfo(context, params) {
      return await GetAxios(activityReviewInfo, params);
    },
    //审核会员列表
    async postUserQualificationMemberAuditReviewList(context, params) {
      return await PostAxios(UserQualificationMemberAuditReviewList, params);
    },
    //会员信息详情
    async getmemberAuditUserInfo(context, params) {
      return await GetAxios(memberAuditUserInfo, params);
    },
    //提交会员审核s
    async postmemberAuditReviewUserInfo(context, params) {
      return await PostAxios(memberAuditReviewUserInfo, params);
    },
    //会员信息详情
    async getlogList(context, params) {
      return await GetAxios(logList, params);
    },
    //佣金提现审核列表
    async postWithdrawList(context, params) {
      return await PostAxios(withdrawList, params);
    },
    // 佣金明细
    async getWithdrawDetailList(context, params) {
      return await GetAxios(withdrawDetailList + "/" + params.id, params);
    },
    //审核通过或审核驳回
    async postwithdrawUpdateStatus(context, params) {
      return await PostAxios(withdrawUpdateStatus, params);
    },
    //查询列表佣金总金额
    async postQueryWithdrawMoney(context, params) {
      return await PostAxios(queryWithdrawMoney, params);
    },
    // 列表佣金总金额导出
    async postWithdrawExport(context, params) {
      return await PostAxios(withdListExport, params);
    },
    // 获取公告列表
    async getAfficheList(context, params) {
      return await GetAxios(afficheList, params);
    },
    // 获取公告详情
    async getAfficheDetail(context, params) {
      return await GetAxios(afficheDetail, params);
    },
    // 公告审核
    async postAfficheAudit(context, params) {
      return await PostAxios(afficheAudit, params);
    },
    // 刷新进度
    async refreshAuditStatus(context, params) {
      return await GetAxios(refreshAuditStatus, params);
    },
    // 获取审核状态资质效期
    async getQualifyEffective(context, params) {
      return await GetAxios(getQualifyEffective, params);
    },
  },
};
