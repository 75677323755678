<template>
  <div class="Menu">
    <el-menu
      class="el-menu-vertical-demo"
      :collapse-transition="true"
      :collapse="!isCloseMenu"
      :default-active="$route.path"
      exact
      :unique-opened="true"
      background-color="#fff"
      text-color="#666"
      active-text-color="#fff"
    >
      <template v-for="(item, index) in Menu">
        <el-submenu
          v-if="item.son.length > 0"
          :index="index + ''"
          :key="item.id"
        >
          <template slot="title">
            <i :class="item.icon" style="color: '#666'"></i>
            <span>{{ item.name }}</span>
          </template>
          <el-menu-item
            :index="elem.path"
            v-for="elem in item.son"
            :key="elem.id"
            @click="toRouter(elem)"
          >
            {{ elem.name }}</el-menu-item
          >
        </el-submenu>
      </template>
      <div
        class="open"
        :class="isCloseMenu ? 'isMenu-open' : 'isMenu-close'"
        tabindex="1"
      >
        <i
          @click="closeMenu"
          :class="isCloseMenu ? 'el-icon-s-fold' : 'el-icon-s-unfold'"
        ></i>
      </div>
    </el-menu>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("power");
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState([`Menu`]),
    ...commonIndex.mapMutations(["AddRoter"]),
    ...commonIndex.mapState(["isCloseMenu", "isCloseMenu"]), //拿去公共库数据
  },
  created() {
    this.MenuList();
    this.pushAddRoter(this.$route);
  },
  watch: {
    $route() {
      this.pushAddRoter(this.$route);
    },
  },
  methods: {
    ...mapActions(["getMenuList"]),
    ...commonIndex.mapActions(["toPushAddRoter"]),
    ...commonIndex.mapMutations(["cutMenuState", "closeSeach"]),
    // 获取侧边栏菜单
    async MenuList() {
      await this.getMenuList();
    },
    // 获取ROUTER
    toRouter(data) {
      let router = data.path.substr(1);
      this.closeSeach(router);
      this.$router.push({ path: data.path });
    },
    // 储存路由
    pushAddRoter(data) {
      // //console.log(data);
      this.toPushAddRoter(data);
    },
    //   关闭菜单列表
    closeMenu() {
      this.cutMenuState();
    },
  },
};
</script>

<style lang='scss' scoped>
.Menu {
  color: #666;
  padding-bottom: 40px;
}
.open {
  position: fixed;
  bottom: 0px;
  left: 0px;
  height: 40px;
  /* border-top: 1px #666 solid; */
  background-color: #fff;
  transition-duration: 2s;
  line-height: 40px;
  padding-left: 15px;
}
.isMenu-open :focus {
  width: 200px;
}
.isMenu-close :focus {
  width: 48px;
}
::v-deep .el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
::v-deep .el-menu--collapse {
  width: 40px !important;
}
::v-deep .el-menu {
  border-right: none !important;
  font-weight: normal;
  /* padding-bottom: 40px; */
}
::v-deep .el-submenu__title i {
  color: #666 !important;
}
::v-deep .el-submenu__title {
  padding: 0 12px !important;
  /* width: 24px !important; */
}
::v-deep .el-menu-item.is-active {
  background-color: #f0fdfc !important;
  color: #06b7ae !important;
}
::v-deep .el-menu-item:hover {
  background-color: #f0fdfc !important;
}
::v-deep .el-submenu__title:hover {
  background-color: #f0fdfc !important;
}
</style>