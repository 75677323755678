/****************php接口***************************************************/
let phpBaseUrl = "";
if (process.env.NODE_ENV == "development") {

  // phpBaseUrl = "http://pt.yaojuli.com:8006/api"// 正式环境
  // phpBaseUrl = "http://prept.yaojuli.com:8006/api"// 灰度环境
  phpBaseUrl = " http://192.168.40.39:8002/api";// 测试环境
  // phpBaseUrl = " http://192.168.40.39:9002/api";// 开发环境
  // phpBaseUrl = "http://.35.232:9502"//罗申洪
  // phpBaseUrl = "http://192.168.35.67:9502"// 陈浩
}
if (process.env.NODE_ENV === "production") {
  phpBaseUrl = window.location.origin + "/api";
}
/****************java接口***************************************************/
// let devUrl = "http://yygproapi.yaojuli.com"; // 阿里云正式地址
// let devUrl = "http://preyygproapi.yaojuli.com"; // 灰度地址
let devUrl = "http://222.209.81.152:8003"; //测试地址
// let devUrl = "http://192.168.40.39:9090"; //开发地址
// let devUrl = "http://192.168.35.103:10007"; //谢锋周地址
// let devUrl = "http://183.220.29.156:8003"; //临时地址
let kfUrlapp = "http://192.168.40.39:9090"; // 开发
let proUrlyun = "http://yygproapi.yaojuli.com"; // 阿里云正式地址
let preproUrlyun = "http://preyygproapi.yaojuli.com"; // 灰度地址
var javaBaseUrl =
  window.location.origin.indexOf("prept.yaojuli.com") != -1
    ? preproUrlyun
    : window.location.origin.indexOf("pt.yaojuli.com") != -1
      ? proUrlyun
      : window.location.origin.indexOf("192.168.40.39:9002") != -1
        ? kfUrlapp
        : devUrl;
/***************************************************************/
window.localStorage.setItem("javaBaseUrl", javaBaseUrl);
export { phpBaseUrl, javaBaseUrl };
