<template>
  <div class="no-Power">
    <el-card class="box-card">
      <div class="wz">
        <img src="@/assets/img/NoPower.png" />
        <div class="cz">
          <div class="btn" @click="backHomeBtn">返回首页</div>
          <div class="btn" @click="toLogin">重新登录</div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    // 无权限返回首页
    backHomeBtn() {
      this.$router.push({ path: "/contentHome" });
    },
    // 重新登录
    toLogin() {
      sessionStorage.removeItem("TOKEN")
      this.$router.push({ path: "/contentHome" });
    },
  },
};
</script>

<style lang="scss" scoped>
.no-Power {
  width: 100%;
  .box-card {
    position: relative;
    width: 100%;
    min-height: 90vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  img {
    width: 600px;
    height: 600px;
  }
  .wz {
    text-align: center;
    font-size: 14px;
  }
  .btn {
    margin: 0px 10px;
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    color: #85a4ec;
  }
  //
  .btn:hover {
    color: #3d70ff;
  }
  .cz {
    display: flex;
    justify-content: center;
  }
}
</style>