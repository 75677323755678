//引入common.js的方法
import AxiosPut from './componentsput'

export function PutAxios(url, data) {
    return new Promise((resolve, reject) => {
        //成功处理
        AxiosPut(url, data).then(res => resolve(res))
            //失败抛出错误
            .catch(error => console.log(error))
    })
}