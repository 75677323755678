<template>
  <div class="AllHome">
    <el-container>
      <el-aside :width="isCloseMenu ? '200px' : '48px'">
        <div class="menu-BOX">
          <!-- logo图标 -->
          <div class="logo" @click="toHome" v-if="isCloseMenu">
            <div class="logo-img">
              <img src="@/assets/img/login-img/yjg-Logo.png" alt="" />
            </div>
            <div class="logo-title"></div>
          </div>
          <div class="logo" @click="toHome" v-else>
            <div class="logo-img">
              <img src="@/assets/img/login-img/yjg-Logo.png" alt="" />
            </div>
          </div>
          <!-- 菜单 -->
          <div class="menu-List">
            <SideMenu />
          </div>
        </div>
      </el-aside>
      <el-container>
        <el-header height="105px">
          <Hearder />
        </el-header>
        <el-main>
          <div
            ref="jo"
            style="height: 100%"
            v-loading="IsLading"
            element-loading-text="请稍等..."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 1)"
          >
            <router-view />
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import SideMenu from "./sideMenu/menu.vue";
import Hearder from "./headerView/header.vue";
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
export default {
  components: {
    SideMenu,
    Hearder,
  },
  data() {
    return {
      screenWidth: document.documentElement.clientWidth, //屏幕宽度
      time: null,
      scrollHeigth: 0,
      loading: true,
    };
  },
  computed: {
    ...commonIndex.mapState(["isCloseMenu", "IsLading"]),
  },
  created() {
    this.getArea();
    this.getUserType();
    this.getUnitTypeList();
    this.GETloanCommissionlist(); //全局获取白条免息配置表
    // this.getbasisscope();
  },
  watch: {
    screenWidth: function (val) {
      if (val < 800) {
        if (this.time) {
          clearInterval(this.time);
        }
        this.time = setTimeout(() => {
          this.time = null;
          this.falseMenu(false);
        }, 100);
      } else {
        if (this.time) {
          clearInterval(this.time);
        }
        this.time = setTimeout(() => {
          this.time = null;
          this.falseMenu(true);
        }, 100);
      }
    },
    scrollHeigth: function (val) {
      //console.log(val, "123");
      this.setstockpileMainHeight(val);
    },
  },
  mounted() {
    var _this = this;
    _this.setstockpileMainHeight(_this.$refs.jo.offsetHeight);
    window.onresize = function () {
      // 定义窗口大小变更通知事件
      _this.screenWidth = document.documentElement.clientWidth; //窗口宽度
      _this.scrollHeigth = _this.$refs.jo.offsetHeight;
    };
  },
  methods: {
    ...commonIndex.mapMutations([
      "cutMenuState",
      "falseMenu",
      "stockpileMainHeight",
    ]),
    ...commonIndex.mapActions([
      "GetcomSearchList",
      "allBotton",
      "getUserType",
      "getUnitTypeList",
      "GETloanCommissionlist",
      "getbasisscope",
      "categorycategory",
      "getbasisscope",
      "getcategorylist",
    ]),
    getArea() {
      this.GetcomSearchList();
      this.allBotton();
      this.getbasisscope();
      this.categorycategory();
      this.getcategorylist();
    },
    //储存高度
    setstockpileMainHeight(data) {
      this.stockpileMainHeight(data);
    },
    //   关闭菜单列表
    closeMenu() {
      this.cutMenuState();
    },
    //   前往首页
    toHome() {
      this.$router.push({ path: "/contentHome" });
    },
    // 退出登录
    downWire() {
      sessionStorage.removeItem("TOKEN");
      this.$router.push({ path: "/" });
    },
  },
};
</script>

<style lang="scss" scoped>
.AllHome {
  height: 100vh;
  .el-header {
    min-width: 800px;
    height: 5vh;
    background-color: #ffffff;
    padding: 0;
    // border-bottom: 1px solid #f2f2f2;
  }

  .el-aside {
    height: 100vh;
    min-height: 600px;
    padding-right: 1px;
    overflow-y: scroll;
    background-color: #fff;
    width: 200px;
    transition: width 1s;
    .menu-BOX {
      width: 100%;
      height: 100%;
      .logo {
        width: 100%;
        height: 60px;
        display: flex;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        background: #fff;
        .logo-img {
          width: 40px;
          height: 40px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 100px;
          }
        }
        .logo-title {
          font-size: 14px;
          margin-left: 10px;
          color: #000;
          font-weight: bold;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      //   菜单
      .menu-List {
        width: 100%;
        .menu-btn {
          margin-top: 20px;
          width: 100%;
          height: 50px;
          background: sandybrown;
        }
      }
    }
  }
  .el-aside::-webkit-scrollbar {
    width: 0 !important;
  }
  .el-main {
    min-width: 800px;
    height: 80vh;
    min-height: 500px;
    padding: 20px !important;
    padding-top: 10px !important ;
    padding-bottom: 20px !important;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: #f2f2f2;
    .tags {
      width: 100%;
      height: 30px;
    }
  }
  /* 修改滚动条样式 */
  .el-main::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 0 !important;
  }
  .el-main::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    width: 0 !important;
  }
}
</style>
