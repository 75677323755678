import { GetAxios } from "@/api/AxiosGet";//get请求
import { PostAxios } from "@/api/AxiosPost"//post请求
import { } from "@/api/phpUrl"//引入接口
export default {
    namespaced: true,
    // 集中数据
    state: {
    },
    // 处理state中的数据
    mutations: {

    },
    // 发送axios请求
    actions: {

    }
}