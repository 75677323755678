import store from '@/store'
// 按钮权限
export default function BtnJurisdiction(data) {
    if (data && store.state.commonIndex.allJurisdiction.length > 0) {
        let btnPower = store.state.commonIndex.allJurisdiction
        let newArr = btnPower.filter(item => {
            return item.api_path == data
        })
        if (newArr.length > 0) {
            return true
        } else {
            return false
        }
    } else {
        return false
    }
}