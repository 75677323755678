import { GetAxios } from "../../../api/AxiosGet"; //get请求
import { PostAxios } from "../../../api/AxiosPost"; //post请求
import {
  userLoanUserInfo,
  userLoanList,
  userLoanItem,
  userLoanUserLoanInfoList,
  pinganLoanssSelectPinganLoans,
  pinganLoanssUpdatePinganLoans,
} from "../../../api/javaUrl"; //引入接口
import { Message } from "element-ui"; //引入elmentui提示框
export default {
  namespaced: true,
  // 集中数据
  state: {

  },
  // 处理state中的数据
  mutations: {

  },
  // 发送异步请求
  actions: {
    // 获取会员信息
    async getUserLoanUserInfo(context, params) {
      let  data = await GetAxios(userLoanUserInfo+params);
      // context.commit("setSaleManList", data);
      return data
    },
    // 获取贷款信息
    async postUserLoanList(context, params) {
      let  data = await PostAxios(userLoanList,params);
      // context.commit("setSaleManList", data);
      return data
    },
    // 还款记录
    async getUserLoanItem(context, params) {
      let  data  = await GetAxios(userLoanItem+params);
      // context.commit("setSaleManList", data);
      return data
    },
    // 药聚力白条贷款记录
    async postUserLoanUserLoanInfoList(context, params) {
      let data= await PostAxios(userLoanUserLoanInfoList,params);
      // context.commit("setSaleManList", data);
      return data
    },
     // 药聚力白条会员管理
     async postPinganLoanssSelectPinganLoans(context, params) {
      let  data = await PostAxios(pinganLoanssSelectPinganLoans,params);
      // context.commit("setSaleManList", data);
      return data
    },
    // 支付权限开关
    async postPinganLoanssUpdatePinganLoans(context, params) {
      let  data = await PostAxios(pinganLoanssUpdatePinganLoans,params);
      // context.commit("setSaleManList", data);
      return data
    },
    // // 修改收货地址
    // async postUsersaveAddress(context, params) {
    //   return await PostAxios(UsersaveAddress, params);
    // },
  },
};
