import { GetAxios } from "../../../api/AxiosGet"; //get请求
import { PostAxios } from "../../../api/AxiosPost"; //post请求
import { PutAxios } from "../../../api/AxiosPut"; //post请求
import {
  addbusinessQualification,
  businessQualificationTypeList,
  addGetLastCode,
  businessQualificationTypeedit,
  templateList,
  changeSelect,
  Qualificationlist,
  Qualificationadd,
  configList,
  configSet,
  templateedit,
  // memberLibrarylist,
  memberTypeLibrarylist, //用户资质库列表
  memberLibrarycreate, //新增资质库
  memberTypeLibraryedit, //用户资质库编辑
  memberTypeLibraryset, //配置会员资质开关
  memberTypelist, //用户资质类型列表
  reviewList, //供应商审核列表
  scopelist, //经营类别列表
  scopegoodsQualify, //资质库列表
  scopeadd, //新增经营类别
  districtList, //省市区列表
  districtadd, //新增类型
  districtedit, //编辑地区
  batchTransfer, //批量转移
  goodsQualifylist, //商品资质列表
  goodsQualifychangeSelect, //商品资质选择切换
  typedelete, //删除经营类别
  typeedit, //经营类别编辑
  goodsQualifyDelte, //删除经营资质
  logisticslist, //物流公司列表
  logisticsadd, //物流公司新增
  logisticsedit, //物流公司编辑
  loanCommissionlist, //免息配置详情
  loanCommissionsave, //保存平台分佣配置
  agreementlist, //协议中心tabs
  agreementeditInfo, //保存协议
  factorylist, //厂家证明材料
  factoryedit, //编辑厂家证明材料
  factoryadd, //新增
  factorydelete, //删除厂家
  goodsQualifyAdd, // 添加商品资质
  goodsQualifyEdit, // 编辑商品资质
  configurationlist, //全局peizhi
  baseConfigedit, //编辑配置
  logisticsdele, //物流删除
  downloadTemplate, //下载物流模板
  qualificationGrouplist, //所需资质列表
  qualificationGroupset, //配置会员所需资质
  businessqualificationGrouplist, //供应商三证资质类型列表
  businessqualificationGroupset, //供应商三证所需资质
  businessgetLastCode, //供应商资质获取编码
  getPlatformIOU, //平台白条分佣规则查询
  updatePlatformIOU, //平台白条分佣规则修改
  userQualificationGetLastCode,
  // userQualificationAdd,
  typeSelectList,
  returnTimelineslist,//退货时效-白名单-列表
  returnTimelinesadd,//退货时效-白名单-添加
  returnTimelinesremove,//退货时效-白名单-移除
  receiveTimelineslist,//收货时效-白名单-列表
  receiveTimelinesadd,//收货时效-白名单-添加
  receiveTimelinesremove,//收货时效-白名单-移除
  customerServiceList,
  createCustomerService,
  updateCustomerService,
  customerServiceStatus,
  systemPageList,
  changeStatus,
  changeSort,
  systemPageAdd,
  systemPageUpdate,
  systemPageDetail
} from "../../../api/phpUrl"; //引入接口
import {
  operatorId,//操作人id
  memberLibrarylist,
  getAllBusinessCategory,
  userQualificationAdd,
  editStatus,
  bankSelectBank,
  bankAddBank,
  bankUpdateBank,
  bankDisabledBank,
  bankDeleteBank,
  bannerSelectBanner,
  bannerInsertBanner,
  bannerUpdateBanner,
  bannerDeleteBanner,
  queryOrderInterceptConfig,
  saveOrderInterceptConfig
} from "../../../api/javaUrl";
import { Message } from "element-ui"; //引入elmentui提示框
export default {
  namespaced: true,
  // 集中数据
  state: {
    EnterpriseTyprList: {}, //存储企业类型列表
    setDataQa: {},
    setQaList: [],
    UserQualificationData: {},
    manageData: {}, //经营类别数据
    setGoodsWarData: {}, //经营资质库
  },
  // 处理state中的数据
  mutations: {
    // 储存store数据
    storageEnterprise(state, data) {
      state.EnterpriseTyprList = data;
    },
    // 储存直至列表数据
    stotrageQaList(state, data) {
      state.setDataQa = data;
    },
    // 储存资质类型
    setQaState(state, data) {
      state.setQaList = data;
    },
    // 储存资质库列表数据
    setqua(state, data) {
      state.UserQualificationData = data;
    },
    // 储存经营类别数据
    setManage(state, data) {
      state.manageData = data;
    },
    // 储存资质库
    setGoodsWar(state, data) {
      data.data.forEach((item) => {
        item.sort = item.sort + "";
      });
      state.setGoodsWarData = data;
    },
    cgdisplay(state, data) {
      state.EnterpriseTyprList.data.forEach((v, i) => {
        if (data.row.company_type_code == v.company_type_code) {
          if (data.type == 1) {
            v.is_unity = data.show;
          } else {
            v.is_no_unity = data.show;
          }
        }
      });
    },

    cguserdisplay(state, data) {
      state.setQaList.data.forEach((v, i) => {
        if (data.row.id == v.id) {
          v.is_display = data.show;
        }
      });
    },
  },
  // 发送异步请求
  actions: {
    //获取平台白条分佣数据
    async getPlatformIOU(context, params) {
      return await PostAxios(getPlatformIOU, params);
    },
    //修改平台白条分佣数据
    async updatePlatformIOU(context, params) {
      return await PostAxios(updatePlatformIOU, params);
    },
    // 添加企业类型
    async postaddbusinessQualification(context, params) {
      return await PostAxios(addbusinessQualification, params);
    },
    // 企业类型列表
    async getBusinessQualificationTypeList(context, params) {
      let data = await GetAxios(businessQualificationTypeList, params);

      data.data.data.forEach((v, i) => {
        v.unity_count = v.unity.length;
        v.no_unity_count = v.no_unity.length;
        v.is_no_unity = false;
        v.is_unity = false;
        v.index = i;
      });
      context.commit("storageEnterprise", data.data);
    },
    // 新增企业类型
    async getaddGetLastCode(context, params) {
      // let data = await GetAxios(addGetLastCode, params);
      return await GetAxios(addGetLastCode, params);
    },
    // 获取编辑企业资质详情
    async getbusinessQualificationTypeedit(context, params) {
      return await GetAxios(businessQualificationTypeedit, params);
    },
    // 编辑企业资质详情
    async postbusinessQualificationTypeedit(context, params) {
      return await PostAxios(businessQualificationTypeedit, params);
    },
    // 企业资质列表
    async gettemplateList(context, params) {
      return await GetAxios(templateList, params);
    },
    // 开启当前资质
    async postchangeSelect(context, params) {
      return await PostAxios(changeSelect, params);
    },
    // 获取资质列表
    async getQualificationlist(context, params) {
      let data = await GetAxios(Qualificationlist, params);
      context.commit("stotrageQaList", data.data);
    },
    // 新增资质
    async postaddQualification(context, params) {
      return await PostAxios(Qualificationadd, params);
    },
    // 获取配置项
    async getconfigList(context, params) {
      return await GetAxios(configList, params);
    },
    // 配置资质属性
    async postconfigSet(context, params) {
      return await PostAxios(configSet, params);
    },
    // 获取资质详情
    async gettemplateedit(context, params) {
      return await GetAxios(templateedit, params);
    },
    // 修改资质详情
    async posttemplateedit(context, params) {
      return await PostAxios(templateedit, params);
    },
    // 用户资质库列表
    async getmemberLibrarylist(context, params) {
      let data = await GetAxios(memberLibrarylist, params);
      context.commit("setqua", data.content);
    },
    // 关联经营类别
    async getAllBusinessCategory(context, params) {
      return await GetAxios(getAllBusinessCategory, params);
    },
    // 会员资质配置
    async postmemberTypeLibrarylist(context, params) {
      return await PostAxios(memberTypeLibrarylist, params);
    },
    // 新增资质库
    async postmemberLibrarycreate(context, params) {
      return await PostAxios(memberLibrarycreate, params);
    },
    // 用户资质库编辑修改
    async postmemberTypeLibraryedit(context, params) {
      return await PostAxios(memberTypeLibraryedit, params);
    },
    // 用户资质库编辑查询
    async getmemberTypeLibraryedit(context, params) {
      return await GetAxios(memberTypeLibraryedit, params);
    },
    // 配置会员资质开关
    async postmemberTypeLibraryset(context, params) {
      return await PostAxios(memberTypeLibraryset, params);
    },
    // 会员资质类型列表
    async getmemberTypelist(context, params) {
      let data = await GetAxios(memberTypelist, params);
      data.data.data.forEach((v, i) => {
        v.is_display = false;
        v.index = i;
      });
      context.commit("setQaState", data.data);
    },
    // 供应商审核列表
    async getreviewList(context, params) {
      return await GetAxios(reviewList, params);
    },
    // 经营类别列表
    async getscopelist(context, params) {
      let data = await GetAxios(scopelist, params);
      context.commit("setManage", data.data);
    },
    // 资质库列表
    async getscopegoodsQualify(context, params) {
      let data = await GetAxios(scopegoodsQualify, params);
      context.commit("setGoodsWar", data.data);
    },
    // 新增经营类别
    async postscopeadd(context, params) {
      return await GetAxios(scopeadd, params);
    },
    // 获取省市区列表
    async getdistrictList(context, params) {
      return await GetAxios(districtList, params);
    },
    // 新增地区
    async postdistrictadd(context, params) {
      return await PostAxios(districtadd, params);
    },
    // 编辑地区
    async postdistrictedit(context, params) {
      return await PostAxios(districtedit, params);
    },
    // 批量转移
    async postbatchTransfer(context, params) {
      return await PostAxios(batchTransfer, params);
    },
    //获取商品资质列表
    async getgoodsQualifylist(context, params) {
      return await GetAxios(goodsQualifylist, params);
    },
    // 选择资质切换
    async postgoodsQualifychangeSelect(context, params) {
      return await PostAxios(goodsQualifychangeSelect, params);
    },
    // 删除经营类被
    async posttypedelete(context, params) {
      return await PostAxios(typedelete, params);
    },
    //经营类别编辑
    async gettypeedit(context, params) {
      return await GetAxios(typeedit, params);
    },
    //经营类别编辑
    async typeSelectList(context, params) {
      return await GetAxios(typeSelectList, params);
    },
    //退货时效-白名单-列表
    async returnTimelineslist(context, params) {
      return await GetAxios(returnTimelineslist, params);
    },
    //退货时效-白名单-添加
    async returnTimelinesadd(context, params) {
      return await PostAxios(returnTimelinesadd, params);
    },
    //退货时效-白名单-移除
    async returnTimelinesremove(context, params) {
      return await PostAxios(returnTimelinesremove, params);
    },
    //收货时效-白名单-列表
    async receiveTimelineslist(context, params) {
      return await GetAxios(receiveTimelineslist, params);
    },
    //收货时效-白名单-添加
    async receiveTimelinesadd(context, params) {
      return await PostAxios(receiveTimelinesadd, params);
    },
    //收货时效-白名单-移除
    async receiveTimelinesremove(context, params) {
      return await PostAxios(receiveTimelinesremove, params);
    },
    //经营类别编辑
    async posttypeedit(context, params) {
      return await PostAxios(typeedit, params);
    },
    // 删除经营资质
    async postgoodsQualifyDelte(context, params) {
      return await PostAxios(goodsQualifyDelte, params);
    },
    // 物流公司列表
    async getlogisticslist(context, params) {
      return await GetAxios(logisticslist, params);
    },
    //物流公司新增
    async postlogisticsadd(context, params) {
      return await PostAxios(logisticsadd, params);
    },
    //物流公司编辑
    async postlogisticsedit(context, params) {
      return await PostAxios(logisticsedit, params);
    },
    // 免息配置项详情
    async getloanCommissionlist(context, params) {
      return await GetAxios(loanCommissionlist, params);
    },
    // 保存平台分佣设置
    async postloanCommissionsave(context, params) {
      return await PostAxios(loanCommissionsave, params);
    },
    // 协议中心tabs
    async getagreementlist(context, params) {
      return await GetAxios(agreementlist, params);
    },
    // 编辑
    async postagreementeditInfo(context, params) {
      return await PostAxios(agreementeditInfo, params);
    },
    // 获取
    async getagreementeditInfo(context, params) {
      return await GetAxios(agreementeditInfo, params);
    },
    // 厂家证明材料
    async getfactorylist(context, params) {
      return await GetAxios(factorylist, params);
    },
    // 编辑
    async postfactoryedit(context, params) {
      return await PostAxios(factoryedit, params);
    },
    // 新增厂家证明材料
    async postfactoryadd(context, params) {
      return await PostAxios(factoryadd, params);
    },
    async postfactorydelete(context, params) {
      return await PostAxios(factorydelete, params);
    },
    // 添加商品资质
    async goodsQualifyAdds(context, params) {
      return await PostAxios(goodsQualifyAdd, params);
    },
    // 编辑商品资质
    async goodsQualifyEdit(context, params) {
      return await PostAxios(goodsQualifyEdit, params);
    },
    // 获取其配置
    async getconfigurationlist(context, params) {
      return await GetAxios(configurationlist, params);
    },
    // 编辑配置
    async postbaseConfigedit(context, params) {
      return await PostAxios(baseConfigedit, params);
    },
    // 物流删除
    async postlogisticsdele(context, params) {
      return await PostAxios(logisticsdele, params);
    },
    // 下载资质
    async getdownloadTemplate(context, params) {
      return await GetAxios(downloadTemplate, params);
    },
    // 所需资质列表
    async getqualificationGrouplist(context, params) {
      return await GetAxios(qualificationGrouplist, params);
    },
    // 所需资质列表
    async getbusinessqualificationGrouplist(context, params) {
      return await GetAxios(businessqualificationGrouplist, params);
    },
    // 会员资质保存
    async postqualificationGroupsete(context, params) {
      return await PostAxios(qualificationGroupset, params);
    },
    // 供应商三证资质保存
    async postbusinessqualificationGroupset(context, params) {
      return await PostAxios(businessqualificationGroupset, params);
    },
    // 所需资质列表
    async getbusinessgetLastCode(context, params) {
      return await GetAxios(businessgetLastCode, params);
    },
    // 会员获取生成编码
    async getuserQualificationGetLastCode(context, params) {
      return await GetAxios(userQualificationGetLastCode, params);
    },
    // 会员添加资质
    async postuserQualificationAdd(context, params) {
      return await PutAxios(userQualificationAdd, params);
    },
    // 会员添加资质
    async puteditStatus(context, params) {
      return await PutAxios(
        editStatus + "/" + params.id + "/" + params.status,
        params
      );
    },
    // 获取银行列表
    async postbankSelectBank(context, params) {
      return await PostAxios(bankSelectBank, params);
    },
    // 新增银行信息
    async postbankAddBank(context, params) {
      return await PostAxios(bankAddBank, params);
    },
    // 编辑银行信息
    async postbankUpdateBank(context, params) {
      return await PostAxios(bankUpdateBank, params);
    },
    // 启用/禁用银行
    async postbankDisabledBank(context, params) {
      return await PostAxios(bankDisabledBank, params);
    },
    // 删除
    async getbankDeleteBank(context, params) {
      return await GetAxios(bankDeleteBank, params);
    },

    async postbannerSelectBanner(context, params) {
      return await PostAxios(bannerSelectBanner, params);
    },

    async postbannerInsertBanner(context, params) {
      params.operatorId = operatorId
      return await PostAxios(bannerInsertBanner, params);
    },

    async postbannerUpdateBanner(context, params) {
      params.operatorId = operatorId
      return await PostAxios(bannerUpdateBanner, params);
    },

    async postbannerDeleteBanner(context, params) {
      params.operatorId = operatorId
      return await PostAxios(bannerDeleteBanner, params);
    },
    // 编辑配置
    async postQueryOrder(context, params) {
      return await GetAxios(queryOrderInterceptConfig, params);
    },
    // 查询下单拦截配置
    async postSaveOrder(context, params) {
      return await PostAxios(saveOrderInterceptConfig, params);
    },
    // 全局配置-客服电话配置-列表
    async getServiceList(context, params) {
      return await GetAxios(customerServiceList, params);
    },
    // 全局配置-客服电话-新增
    async createCustomerService(context, params) {
      return await PostAxios(createCustomerService, params);
    },
    // 全局配置-客服电话-更新
    async updateCustomerService(context, params) {
      return await PostAxios(updateCustomerService, params);
    },
    // 全局配置-客服电话-启停用
    async customerServiceStatus(context, params) {
      return await PostAxios(customerServiceStatus, params);
    },
    // 广告-系统页面列表
    async systemPageList(context, params) {
      return await GetAxios(systemPageList, params);
    },
    // 广告-系统页面状态更改
    async changeStatus(context, params) {
      return await PostAxios(changeStatus, params);
    },
    // 广告-系统页面排序更改
    async changeSort(context, params) {
      return await PostAxios(changeSort, params);
    },
    // 广告-系统页面详情
    async systemPageDetail(context, params) {
      return await GetAxios(systemPageDetail, params);
    },
    // 广告-系统页面新增
    async systemPageAdd(context, params) {
      return await PostAxios(systemPageAdd, params);
    },
    // 广告-系统页面编辑
    async systemPageUpdate(context, params) {
      return await PostAxios(systemPageUpdate, params);
    },
  },
};
