//引入common.js的方法
import AxiosGet from './componentsget'

export function GetAxios(url, params) {
    return new Promise((resolve, reject) => {
        //成功处理
        AxiosGet(url, params).then(res => resolve(res))
            //失败抛出错误
            .catch(error => reject(error))
    })
}