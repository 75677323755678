import { GetAxios } from "../../../api/AxiosGet"; //get请求
import { PostAxios } from "../../../api/AxiosPost"; //post请求
import {
  business_ledgerdelete,
  business_ledgeradd,
  business_ledgeredit,
  business_ledgerlist,
  businessgetReturnAddress,
  getBusinessList,
  getBusinessInfo,
  getCredentials,
  getBusinessScopeList,
  getDistrictList,
  changeBusinessSelect,
  businessEdit,
  reviewSetLoan,
  selectIOU,
  updateIOU,
  complainsRecords,
  getAccessConfig,
  updateAccessConfig,
  getRequisitionConfig,
  updateRequisitionConfig,
  requisitionConfigDetail
} from "../../../api/phpUrl"; //引入接口
import {
  ERPgetAllUserScope,
  ERPuserScopeQuery,
  ERPcreate,
  ERPupdate,
  ERPdelete,
  ERPbusinessInfo,
  ERPgetAllUserQualification,
  ERPuserQualificationQuery,
  ERPgetUserQualifyType,
  ERPuserTypeQuery,
  businessListApi,
  businessGetPayType,
  businessUpdatePayType,
  syncUser
} from "../../../api/javaUrl"; //引入接口
import { Message } from "element-ui"; //引入elmentui提示框
export default {
  namespaced: true,
  // 集中数据
  state: {
    // 供应商列表
    businessList: [],
    // 供应商列表总条数
    total: 0,
    // 当前页条数
    page: 0,
    // 供应商基本信息
    businessInfo: {
      content: { enterprise_type_name: [] },
    },
    // 供应商经营范围类型
    businessScopeList: [],
    // 供应商可经营地区列表
    businessDistrictList: [],
    selectNode: [],
    // 退货地址
    returnArea: {},
  },
  // 处理state中的数据
  mutations: {
    // 设置供应商列表数据
    setBusinessList(state, data) {
      console.log(111, data)
      data.data.forEach((item) => {
        item.address = item.province_name + item.city_name + item.district_name;
      });
      state.businessList = data.data;
      state.total = data.total;
      state.page = data.per_page;
    },
    // 设置供应商详情
    setBusinessInfo(state, data) {
      state.businessInfo = data;
    },
    // 设置供应商经营类型
    setBusinessScopeList(state, data) {
      state.businessScopeList = data;
    },
    // 修改供应商经营类型有效期
    setBusinessLongTime(state, index) {
      if (
        state.businessScopeList[index].expiration_time == null ||
        state.businessScopeList[index].expiration_time == ""
      ) {
        state.businessScopeList[index].isLongTime = true;
      } else {
        state.businessScopeList[index].isLongTime = false;
      }
    },
    // 设置供应商可经营地区
    setDistrictList(state, data) {
      state.businessDistrictList = data;
      var selectNode = [];
      data.map((v) => {
        selectNode.push(v.district_id);
      });
      state.selectNode = selectNode;
    },
    // 存储退货地址
    setretu(sate, data) {
      sate.returnArea = data;
    },
  },
  // 发送异步请求
  actions: {
    //获取白条分佣数据
    async getselectIOU(context, params) {
      return await PostAxios(selectIOU, params);
    },
    //修改白条分佣数据
    async updateIOU(context, params) {
      return await PostAxios(updateIOU, params);
    },
    // 获取供应商列表
    async getBusinessList(context, params) {
      let { data } = await PostAxios(getBusinessList, params);
      context.commit("setBusinessList", data);
    },
    // 获取供应商详情
    async getBusinessInfo(context, params) {
      let { data } = await GetAxios(getBusinessInfo, params);
      context.commit("setBusinessInfo", data);
      return data;
    },
    // 审核记录详情（资质详情）
    async getCredentials(context, params) {
      let { data } = await GetAxios(getCredentials, params);
      data.examineBaseInfo.content = data.qualification;
      context.commit("setBusinessInfo", data.examineBaseInfo);
      return data;
    },
    // 获取供应商经营范围
    async getBusinessScopeList(context, params) {
      let { data } = await GetAxios(getBusinessScopeList, params);
      // 设置默认是否长期值
      data.map((v) => {
        if (v.expiration_time == null || v.expiration_time == "") {
          v.isLongTime = true;
        } else {
          v.isLongTime = false;
        }
        if (v.business_scope_id == 0) {
          v.action = "delete";
        } else {
          v.action = "add";
        }
      });
      context.commit("setBusinessScopeList", data);
      return data;
    },
    // 获取供应商可经营地区
    async getDistrictList(context, params) {
      let { data } = await GetAxios(getDistrictList, params);
      context.commit("setDistrictList", data);
    },
    // 配置可经营范围
    async changeBusinessSelect(context, params) {
      let { data } = await PostAxios(changeBusinessSelect, params);
    },
    // 供应商启用/停用
    async businessEdit(context, params) {
      return await PostAxios(businessEdit, params);
    },
    // 设置白条分期
    async reviewSetLoan(context, params) {
      return await PostAxios(reviewSetLoan, params);
    },
    // 获取退货地址
    async getbusinessgetReturnAddress(context, params) {
      let { data } = await GetAxios(businessgetReturnAddress, params);
      context.commit("setretu", data);
    },
    // 获取分账列表
    async getbusiness_ledgerlist(context, params) {
      return await GetAxios(business_ledgerlist, params);
    },
    // 获取详情
    async getbusiness_ledgeredit(context, params) {
      return await GetAxios(business_ledgeredit, params);
    },
    // 编辑
    async postbusiness_ledgeredit(context, params) {
      return await PostAxios(business_ledgeredit, params);
    },
    // 新建
    async postbusiness_ledgeradd(context, params) {
      return await PostAxios(business_ledgeradd, params);
    },
    // 删除
    async postbusiness_ledgerdelete(context, params) {
      return await PostAxios(business_ledgerdelete, params);
    },
    // 经营范围对码 平台经营范围
    async GetERPgetAllUserScope(context, params) {
      return await GetAxios(ERPgetAllUserScope, params);
    },
    // 经营范围对码 经营范围对码
    async GetERPuserScopeQuery(context, params) {
      return await GetAxios(ERPuserScopeQuery, params);
    },
    // 经营范围对码 平台ERP对码信息创建
    async postERPcreate(context, params) {
      return await PostAxios(ERPcreate, params);
    },
    // 经营范围对码 平台ERP对码信息修改
    async postERPupdate(context, params) {
      return await PostAxios(ERPupdate, params);
    },
    // 经营范围对码 平台ERP对码信息删除
    async GetERPdelete(context, params) {
      return await GetAxios(ERPdelete + params);
    },
    // 供应商信息
    async GetERPbusinessInfo(context, params) {
      return await GetAxios(ERPbusinessInfo + params);
    },
    // 会员资质对码 平台会员资质
    async GetERPgetAllUserQualification(context, params) {
      return await GetAxios(ERPgetAllUserQualification, params);
    },
    // 会员资质对码 会员资质对码
    async getERPuserQualificationQuery(context, params) {
      return await GetAxios(ERPuserQualificationQuery, params);
    },
    // 会员类型对码 获取平台会员类型
    async GetERPgetUserQualifyType(context, params) {
      return await GetAxios(ERPgetUserQualifyType, params);
    },
    // 会员资质对码 平台会员类型对码查询
    async postERPuserTypeQuery(context, params) {
      return await PostAxios(ERPuserTypeQuery, params);
    },
    //
    async postbusinessList(context, params) {
      // let { data } = await PostAxios(businessListApi, params);
      // console.log(data)
      // context.commit("setBusinessList", data);
      return await PostAxios(businessListApi, params);
    },
    // 
    async GetbusinessGetPayType(context, params) {
      return await GetAxios(businessGetPayType, params);
    },
    async postbusinessUpdatePayType(context, params) {
      // let { data } = await PostAxios(businessListApi, params);
      // console.log(data)
      // context.commit("setBusinessList", data);
      return await PostAxios(businessUpdatePayType, params);
    },
    // 获取投诉列表
    async getComplainsRecords(context, params) {
      return await GetAxios(complainsRecords, params);
    },
    // ERP手动配置
    async getsyncUser(context, params) {
      return await GetAxios(syncUser, params);
    },
    // 供应商ERP对接方式查询
    async getAccessConfig(context, params) {
      return await GetAxios(getAccessConfig, params);
    },
    // 供应商ERP对接方式编辑
    async updateAccessConfig(context, params) {
      return await PostAxios(updateAccessConfig, params);
    },
    // 获取供应商自动请货对接信息
    async getRequisitionConfig(context, params) {
      return await GetAxios(getRequisitionConfig, params);
    },
    // 编辑保存供应商自动请货对接信息
    async updateRequisitionConfig(context, params) {
      return await PostAxios(updateRequisitionConfig, params);
    },
    // 供应商自动请货对接信息详情
    async requisitionConfigDetail(context, params) {
      return await GetAxios(requisitionConfigDetail, params);
    },
  },
};
