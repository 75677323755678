import { GetAxios } from "@/api/AxiosGet"; //get请求
import { PostAxios } from "../../../api/AxiosPost"; //post请求
import {
  comSearchList,
  categorylist,
  allBotton,
  userTypeList,
  unitType,
  loanCommissionlist,
  basisscope,
  categorycategory,
  getGoodsImportRecordList,
  goodsImportAudit,
  getPlatformGoodsSupplier,
  deletePlatformGoods,
  syncSupplierGoods
} from "@/api/phpUrl"; //引入接口
import commun from "../Power/power";
import router from "@/router";
import { _params } from "babel-standalone";
export default {
  namespaced: true,
  // 集中数据
  state: {
    isCloseMenu: true, //菜单折叠
    allJurisdiction: {}, //所有按钮权限
    userType: [], //用户等级
    AreaList: [], //所有地区
    MoreAreaChecked: [
      {
        area: [],
      },
    ], //
    countDownTime: {},
    skipRouterList: [], //面包屑导航
    tagsViewList: [], //tag导航标签
    mainHright: 0, //储存main的盒子高度
    userUnitType: [], // 所有单位注册类型
    whitebarList: [], //白条免息配置表
    BasissCopeData: {
      Newbusiness_scope_id: [], // 储存经营类别
      notNewbusinessScopeId: [],//新增无商品经营类别
      Newgoods_category_id: [], //储存商品分类选择器
      Newgoods_categoryList: [],
      inquiregoods_categoryList: [],
    }, //经营类别数据
    FinilsCategoryList: [], //修改完成商品分类结构
    IsLading: false, //Loading动画开启和关闭
    PermissionChange: true, //权限变更弹窗
    pastDue: true,//登录过期
    detailsSeach: {}, //搜索分页保存
    refreshIndex: null,
    goodsDialogVisible: false
  },
  // 处理state中的数据
  mutations: {
    ChangegoodsDialogVisible(state, data) {
      state.goodsDialogVisible = data;
      // state, data
    },
    ChangePermission(state, data) {
      state.PermissionChange = data;
    },
    // 开启菜单
    cutMenuState(state) {
      state.isCloseMenu = !state.isCloseMenu;
    },
    // 关闭菜单
    falseMenu(state, data) {
      state.isCloseMenu = data;
    },
    //存储地区
    StorageArea(state, data) {
      state.AreaList = data;
    },
    // 存储用户类型
    setUesrType(state, data) {
      state.userType = data;
    },
    // 储存按钮权限
    StorageBtn(state, data) {
      let obj = {};
      data.forEach((v) => {
        let key = v.path + v.id;
        let keywodrd = key.slice(1);
        obj[keywodrd] = v.permissions;
      });
      state.allJurisdiction = obj;
    },
    // 更新地区选择
    TheUpdateRegion(state, data) {
      state.MoreAreaChecked = [];
      if (data != undefined) {
        data.forEach((item) => {
          // 判断是否选择区
          if (item.city_id) {
            state.MoreAreaChecked.push({
              area: [item.province_id, item.region_id, item.city_id],
            });
            // 判断是否选择市
          } else if (item.region_id) {
            state.MoreAreaChecked.push({
              area: [item.province_id, item.region_id],
            });
          } else if (item.province_id) {
            state.MoreAreaChecked.push({
              area: [item.province_id],
            });
          } else {
            state.MoreAreaChecked.push({
              area: [],
            });
          }
        });
      } else {
        state.MoreAreaChecked = [
          {
            area: [],
          },
        ];
      }
    },
    // 离开当前页面
    leave() {
      router.go(-1);
    },
    // 放进前往的路由
    AddRoter(state, data) {
      // 组装数据
      let newDate = { name: data.name, path: data.path, query: data.query };
      if (newDate.path != `/contentHome` && newDate.path != `/refresh`) {
        let NewTagArr = state.tagsViewList.filter((item) => {
          return item.path == newDate.path;
        });
        NewTagArr.length > 0
          ? (state.tagsViewList = state.tagsViewList)
          : state.tagsViewList.push(newDate);
        state.tagsViewList.length >= 10
          ? state.tagsViewList.shift()
          : (state.tagsViewList = state.tagsViewList);
      }
      // 判断是否有相同的二级菜单有则清空无在进行push
      commun.state.Menu.forEach((item) => {
        let screenData = item.son.filter((elem) => {
          return elem.path == newDate.path || elem.path == `/contentHome`;
        });
        screenData.length > 0 ? (state.skipRouterList = []) : "";
      });
      // 判断相同3集菜单
      let filterList = state.skipRouterList.filter((item) => {
        return item.path == newDate.path;
      });
      filterList.length == 0 ? state.skipRouterList.push(newDate) : "";
    },
    // 删除tags
    deltelTagsView(state, path) {
      state.tagsViewList.forEach((v, i) => {
        if (v.name == path.name && v.path == path.path) {
          state.tagsViewList.splice(i, 1);
        }
      });
      if (state.tagsViewList.length > 0) {
        // router.push({ path: "/contentHome" });
        // console.log(state.tagsViewList[state.tagsViewList.length - 1]) 
        router.push({
          path: state.tagsViewList[state.tagsViewList.length - 1].path,
          query: state.tagsViewList[state.tagsViewList.length - 1].query,
        });
      } else {
        router.push({ path: "/contentHome" });
      }
    },
    // 清空tags
    emptyTages(state) {
      state.tagsViewList = [];
      router.push({ path: "/contentHome" });
    },
    // 关闭其他tags
    closeElseTages(state, data) {
      if (data == "contentHome") {
        state.tagsViewList = [];
        router.push({ path: "/contentHome" });
        // router.push({ path: data.path });
      } else {
        state.tagsViewList = [data];
        router.push({ path: data.path });
      }
    },
    // 刷新当前页数据
    refreshData(state, data) {
      if (data != 'contentHome') {
        state.tagsViewList.forEach((item, index) => {
          if (item.path == data.path) {
            state.refreshIndex = index
          }
        })
      } else {
        state.refreshIndex = -1
      }

      router.push({ path: "/refresh" });
      // router.removeRoute(data.path)
      // router.push({ path: data.path });
    },
    revolve(state) {
      if (state.refreshIndex != -1) {
        let path = state.tagsViewList[state.refreshIndex]
        router.push({ path: path.path, query: path.query });
      } else {
        router.push({ path: "/contentHome" });
      }


    },
    //存储main的盒子高度
    stockpileMainHeight(state, data) {
      state.mainHright = (data * 70) / 100;
    },
    // 设置所有注册类型
    setUnitType(state, data) {
      state.userUnitType = data;
    },
    // 储存白天免息配置表
    setloanCommissionlist(state, data) {
      state.whitebarList = data;
    },
    // 储存经营类别
    setBasissCope(state, data) {
      state.BasissCopeData.Newbusiness_scope_id = data.data;
      state.BasissCopeData.notNewbusinessScopeId = JSON.parse(JSON.stringify(data.data))
      state.BasissCopeData.notNewbusinessScopeId.push({
        name: '无商品经营类别',
        id: 0
      })
    },
    //储存商品分类选择器
    setcategorycategory(state, data) {
      state.BasissCopeData.Newgoods_category_id = data.data;
      state.BasissCopeData.Newgoods_category_id.push({
        category_name: "无商品分类",
        id: -1,
      });
    },
    setNewgoods_categoryList(state, data) {
      data.data.forEach((item) => {
        if (item.status_ == 1) {
          item.forbidden = false;
        } else {
          item.forbidden = true;
        }
      });
      state.BasissCopeData.Newgoods_categoryList = [...data.data];
      state.BasissCopeData.inquiregoods_categoryList = [...data.data];
      state.BasissCopeData.inquiregoods_categoryList.unshift({
        category_name: "无商品分类",
        id: -1,
      });
      state.FinilsCategoryList = data.data;
    },

    changeSeach(state, data) {

      delete data[data.router];

      state.detailsSeach[data.router] = data;

      // if (state.detailsSeach[data.router]) {

      //   state.detailsSeach[data.router] = data[data.router];
      // } else {

      //   state.detailsSeach[data.router] = data[data.router];
      // }

    },
    closeSeach(state, data) {
      delete state.detailsSeach[data];
    },
    // // 开启Loading
    // openLoading(state) {
    //     // state.IsLading = true
    // },
    // // 关闭Loading
    // closeLoading(state) {
    //     // state.IsLading = false
    // }
  },
  actions: {
    // 获取用户类型
    async getUserType(context) {
      let { data } = await GetAxios(userTypeList);
      context.commit("setUesrType", data);
      return data;
    },
    // 地区获取地区
    async GetcomSearchList(context) {
      let { data } = await GetAxios(comSearchList);
      context.commit("StorageArea", data);
    },
    // 获取所有按钮权限
    async allBotton(context) {
      let { data } = await GetAxios(allBotton);
      context.commit("StorageBtn", data);
    },
    // 去存储路由
    toPushAddRoter(context, data) {
      context.commit("AddRoter", data);
    },
    // 获取所有注册类型
    async getUnitTypeList(context) {
      let { data } = await GetAxios(unitType);
      context.commit("setUnitType", data);
    },
    // 白条免息配置列表
    async GETloanCommissionlist(context) {
      let { data } = await GetAxios(loanCommissionlist);
      context.commit("setloanCommissionlist", data);
    },
    // 经营类别选择器
    async getbasisscope(context) {
      let data = await GetAxios(basisscope);
      context.commit("setBasissCope", data);
      return data
    },
    // 商品分类选择器
    async categorycategory(context) {
      let data = await GetAxios(categorycategory);
      context.commit("setcategorycategory", data);
    },
    async getcategorylist(context, params) {
      let data = await GetAxios(categorylist, params);
      context.commit("setNewgoods_categoryList", data);
      return data;
    },
    // 商品资料库-商品导入记录
    async getGoodsImportRecordList(context, params) {
      return await GetAxios(getGoodsImportRecordList, params);
    },
    // 商品导入文件审核
    async postGoodsImportAudit(context, params) {
      return await PostAxios(goodsImportAudit, params);
    },
    // 商品资料库-获取使用商品资料的供应商
    async getPlatformGoodsSupplier(context, params) {
      return await GetAxios(getPlatformGoodsSupplier, params);
    },
    // 商品资料库-删除资料库商品
    async deletePlatformGoods(context, params) {
      return await PostAxios(deletePlatformGoods, params);
    },
    // 商品资料库-更新供应商商品资料
    async syncSupplierGoods(context, params) {
      return await PostAxios(syncSupplierGoods, params);
    },
  },
};
