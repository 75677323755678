import {
    GetAxios
} from "../../../api/AxiosGet"; //get请求
import {
    PostAxios
} from "../../../api/AxiosPost" //post请求
import {
    // orderlist, //订单列表 转java
    // ordertimeLine, //订单时间线
    // orderdetailGoodsList, //订单列表
    // orderdetailBasis, //订单基础信息
    // orderCenterdetailCourier, //订单物流
    orderdetail, //订单证件基础信息
    orderqualification, //订单证件资质
    orderdownPdf, //下载资质
    downloadUserInfo,
    basistime,
    qualifyDetail,
    getExportKey,
    getexportOrder,
    cancelOrder,
    getPaymentTypeList,
    orderOutbound,
    getSingleAccompanying,
    downloadOrderOutbound,
    orderAlertconfigList,// 预警配置-配置列表
    configDetail,// 预警配置-详情
    configEdit,// 预警配置-编辑
    batchEdit,// 预警配置-批量编辑
    searchList,// 订单预警-列表
    exportRecord,// 订单预警-导出
    nodeSelect,// 预警节点下拉选择
    orderApplicationList,
    businessSearch,
    orderApplicationDetails,
    orderApplicationItemList
} from "../../../api/phpUrl" //引入接口
import {
    ordertimeLine, //订单时间线
    orderdetailBasis, //订单基础信息
    orderCenterdetailCourier, //订单物流
    orderdetailGoodsList, //订单列表
} from "../../../api/javaUrl" //引入接口
import {
    orderlist, //订单列表
    orderStatistics,// 订单统计
} from "../../../api/javaUrl" //引入接口
import {
    Message
} from 'element-ui' //引入elmentui提示框
import {
    _params
} from "babel-standalone";
export default {
    namespaced: true,
    // 集中数据
    state: {
        cardData: {}, //证件基本信息
        qualificationData: {}, //证件资质
        getexportOrder: getexportOrder
    },
    // 处理state中的数据
    mutations: {
        //证件基本信息
        setcard(state, data) {
            data.business_category = data.business_category.filter(item => {
                return item.status != 0
            })
            state.cardData = data
        },
        //证件资质信息
        setqualification(state, data) {
            state.qualificationData = data
        }
    },
    // 发送异步请求
    actions: {
        // 获取订单列表
        async postorderlist(context, params) {
            return await PostAxios(orderlist, params)
        },
        // 导出订单列表密钥
        async postgetExportKey(context, params) {
            return await PostAxios(getExportKey, params)
        },
        // 取消订单
        async postcancelOrder(context, params) {
            return await PostAxios(cancelOrder, params)
        },
        //导出订单列表
        // async getgetexportOrder(context, params) {
        //     return await GetAxios(getexportOrder, params)
        // },
        // 订单时间线
        async getordertimeLine(context, params) {
            return await GetAxios(ordertimeLine, params)
        },
        // 订单商品列表
        async getorderdetailGoodsList(context, params) {
            return await GetAxios(orderdetailGoodsList, params)
        },
        // 订单基础信息
        async getorderdetailBasis(context, params) {
            return await GetAxios(orderdetailBasis, params)
        },
        // 订单物流
        async getorderCenterdetailCourier(context, params) {
            return await GetAxios(orderCenterdetailCourier, params)
        },
        // 订单证件基础信息
        async getorderdetail(context, params) {
            let data = await GetAxios(orderdetail, params)
            context.commit("setcard", data.data)
        },
        async getorderqualification(context, params) {
            let data = await GetAxios(orderqualification, params)

            if (data.code == 200) {
                context.commit("setqualification", data.data)
            } else {
                Message({
                    type: 'success',
                    message: data.message
                })
            }

        },
        // 获取会员资质详情
        async getQualifyDetail(context, params) {
            let {
                data
            } = await GetAxios(qualifyDetail, params);
            return data
        },
        // 下载资质
        async getorderdownPdf(context, params) {
            return await GetAxios(orderdownPdf, params)
        },
        // 下载资质
        async postdownloadUserInfo(context, params) {
            return await PostAxios(downloadUserInfo, params)
        },
        // 时间
        async getbasistime(context, params) {
            return await GetAxios(basistime, params)
        },
        // 前端获取支付方式列表
        async getPaymentTypeList(context, params) {
            return await GetAxios(getPaymentTypeList, params)
        },
        // 订单统计
        async orderStatistics(context, params) {
            return await PostAxios(orderStatistics, params)
        },
        // 订单中心-获取出库单
        async orderOutbound(context, params) {
            return await PostAxios(orderOutbound, params)
        },
        // 通过单个出库单号查询随货同行单
        async getSingleAccompanying(context, params) {
            return await PostAxios(getSingleAccompanying, params)
        },
        // 订单详情-下载随货同行单
        async downloadOrderOutbound(context, params) {
            return await PostAxios(downloadOrderOutbound, params)
        },
        // 预警配置-配置列表
        async orderAlertconfigList(context, params) {
            return await GetAxios(orderAlertconfigList, params)
        },
        // 预警配置-详情
        async configDetail(context, params) {
            return await GetAxios(configDetail, params)
        },
        // 预警配置-编辑
        async configEdit(context, params) {
            return await PostAxios(configEdit, params)
        },
        // 预警配置-批量编辑
        async batchEdit(context, params) {
            return await PostAxios(batchEdit, params)
        },
        // 订单预警-列表
        async searchList(context, params) {
            return await GetAxios(searchList, params)
        },
        // 订单预警-导出
        async exportRecord(context, params) {
            return await GetAxios(exportRecord, params)
        },
        // 预警节点下拉选择
        async nodeSelect(context, params) {
            return await GetAxios(nodeSelect, params)
        },
        // 自动请货申请单-列表
        async orderApplicationList(context, params) {
            return await GetAxios(orderApplicationList, params)
        },
        // 供应商搜索
        async businessSearch(context, params) {
            return await GetAxios(businessSearch, params)
        },
        //自动请货申请单-详情
        async orderApplicationDetails(context, params) {
            return await GetAxios(orderApplicationDetails, params)
        },
        //自动请货申请单-详情（品种数据）列表
        async orderApplicationItemList(context, params) {
            return await GetAxios(orderApplicationItemList, params)
        }
    },
}