import { javaBaseUrl } from "../utils/anyUrl.js";
import axios from "axios";
var BaseUrl = javaBaseUrl;
let financeURL = BaseUrl + '/ptfinance' //金融管理模块
let userURL = BaseUrl + '/ptuser' //角色模块
let mgEngineURL = BaseUrl + '/mgengine' //同步
export const javawholeUrl = BaseUrl;
export const javaUploadUrl = BaseUrl + "/ptuser/common/upload" //JAVA图片上传
export const operatorId = sessionStorage.getItem('admin_id');

// 金融管理
export const userLoanUserInfo = financeURL + "/userLoan/userInfo/" //获取会员信息
export const userLoanList = financeURL + "/userLoan/list" //获取贷款信息
export const userLoanItem = financeURL + "/userLoan/item/" //还款记录
export const userLoanUserLoanInfoList = financeURL + "/userLoan/userLoanInfoList" //药聚力白条贷款记录
export const pinganLoanssSelectPinganLoans = userURL + "/pinganLoans/selectPinganLoans" //药聚力白条会员管理
export const pinganLoanssUpdatePinganLoans = userURL + "/pinganLoans/updatePinganLoans" //支付权限开关

// 订单中心
export const orderlist = userURL + "/order/getOrderInfoList" //获取会员信息
export const orderCenterdetailCourier = userURL + "/orderItem/selectLogistics" //根据订单获取物流信息
export const orderdetailBasis = userURL + "/orderItem/selectDetailBasis" //订单基础信息
export const ordertimeLine = userURL + "/orderItem/selectJobStatus" //订单时间线
export const orderdetailGoodsList = userURL + "/orderItem/selectOrderItem" //订单商品
export const orderStatistics = userURL + "/order/orderStatistics" ///order/orderStatistics
// export const orderlist = userURL + "/order/getOrderInfoList" //获取会员信息
// export const orderlist = userURL + "/order/getOrderInfoList" //获取会员信息
// export const orderlist = userURL + "/order/getOrderInfoList" //获取会员信息

//会员中心
export const userEdit = userURL + "/platform/getUserInfo" //查询用户信息
export const updateAccount = userURL + "/platform/updateAccount" //修改账号
export const updateUserInfo = userURL + "/platform/updateUserInfo" //修改用户信息

//基础设置
export const memberLibrarylist = userURL + "/Qualification/getTemplates" //会员入驻配置-资质库
export const getAllBusinessCategory = userURL + "/Qualification/getAllBusinessCategory" //关联经营类别
export const userQualificationAdd = userURL + "/Qualification/replaceTemplate" //修改或添加 会员入驻配置-资质库
export const editStatus = userURL + "/Qualification/editStatus" //会员入驻配置-资质库 修改状态
export const queryOrderInterceptConfig = mgEngineURL + "/pub/orderInterceptConfig/queryOrderInterceptConfig"//查询下单拦截配置
export const saveOrderInterceptConfig = mgEngineURL + "/pub/orderInterceptConfig/saveOrderInterceptConfig"//新增或修改下单拦截配置

//会员审核
export const memberAuditstatusLine = userURL + "/Audit/statusLine" //时间线
export const memberAudituserDetail = userURL + "/Audit/selectUserDetail" //会员基础资料
export const memberAudituserqualify = userURL + "/Audit/selectQualify" //证件资料
export const memberAuditaudit = userURL + "/Audit/audit" //会员审核
export const memberAuditsavesLine = userURL + "/Audit/savesLine" //审核时间线
export const memberAuditunit = userURL + "/Audit/unit" //单位信息详情
export const memberAuditaddressMsg = userURL + "/Audit/addressMsg" //收货信息
export const memberAuditsavesaveUserInfo = userURL + "/Audit/saveUserInfo"//保存会员信息
export const memberAuditscopeBusiness = userURL + "/Audit/scopeBusiness" //获取经营范围
export const memberAuditsaveScopeBusiness = userURL + "/Audit/saveScopeBusiness" //保存经营范围
// 供应商中心

export const ERPgetAllUserScope = userURL + "/erpCodeMatch/getAllUserScope" //经营范围对码 平台经营范围
export const ERPuserScopeQuery = userURL + "/erpCodeMatch/userScopeQuery" //经营范围对码 经营范围对码
export const ERPcreate = userURL + "/erpCodeMatch/create" //（经营范围对码） 平台ERP对码信息创建
export const ERPupdate = userURL + "/erpCodeMatch/update" //（经营范围对码） 平台ERP对码信息修改
export const ERPdelete = userURL + "/erpCodeMatch/delete/" //（经营范围对码） 平台ERP对码信息修改
export const ERPimportScope = userURL + "/erpCodeMatch/importScope/" //（经营范围对码） 导入经营范围对码
export const ERPbusinessInfo = userURL + "/erpCodeMatch/businessInfo/" //供应商信息

export const ERPgetAllUserQualification = userURL + "/erpCodeMatch/getAllUserQualification" //会员资质对码 平台会员资质
export const ERPuserQualificationQuery = userURL + "/erpCodeMatch/userQualificationQuery" //会员资质对码 会员资质对码
export const ERPimportQualification = userURL + "/erpCodeMatch/importQualification/" //会员资质对码 导入会员资质对码

export const ERPgetUserQualifyType = userURL + "/erpCodeMatch/getUserQualifyType" //会员类型对码 获取平台会员类型
export const ERPuserTypeQuery = userURL + "/erpCodeMatch/userTypeQuery" //会员类型对码 平台会员类型对码查询
export const ERPimportUserType = userURL + "/erpCodeMatch/importUserType" //会员类型对码 导入会员类型对码
export const syncUser = userURL + "/erpCodeMatch/syncUser" //ERP手动配置

export const businessListApi = mgEngineURL + "/business/list" //供应商列表
export const businessGetPayType = mgEngineURL + "/business/getPayType" //获取支付列表
export const businessUpdatePayType = mgEngineURL + "/business/updatePayType" //更改支付




// 商品中心
export const configSetDetail = mgEngineURL + "/configSet/detail" //商品资料库 资料库更新设置详情
export const configSetUpdate = mgEngineURL + "/configSet/update" //商品资料库 更新设置
export const syncBigDataSyncPicture = mgEngineURL + "/syncBigData/syncPicture/" //商品资料库 商品详情 同步图片
export const syncBigDataSyncInstruction = mgEngineURL + "/syncBigData/syncInstruction/" //商品资料库 商品详情 同步说明书
export const syncBigDataRun = mgEngineURL + "/syncBigData/run" //商品资料库  更新商品信息 全部
export const syncBigDataProgress = mgEngineURL + "/syncBigData/progress" //商品资料库 更新商品信息 进度
export const goodsList = mgEngineURL + "/goods/list" //商品资料库 商品资料库列表
export const goodsDetail = mgEngineURL + "/goods/detail" //商品资料库 商品资料库详情
export const goodsEdit = mgEngineURL + "/goods/edit" //商品资料库 商品资料库编辑保存
export const updateForbidTheSaleStatus = mgEngineURL + "/platformGoods/updateForbidTheSaleStatus" //商品资料库 修改禁售状态
export const platformGoodsImportGoods = mgEngineURL + "/platformGoods/importGoodsNew" //商品资料库 平台商品资料库导入
export const goodsGetCategoryById = mgEngineURL + "/goods/getCategoryById" //商品资料库 根据二维数组分类id查询分类
export const saveBaseInfo = mgEngineURL + "/goods/saveBaseInfo" //新增商品资料基本信息
export const saveExtendsInfo = mgEngineURL + "/goods/saveExtendsInfo/" + operatorId //新增商品资料补充信息
export const saveInstructions = mgEngineURL + "/goods/saveInstructions/" + operatorId //新增商品资料说明书
export const newEdit = mgEngineURL + "/goods/newEdit" //商品资料库编辑保存
export const getPlatformGoodsDetail = mgEngineURL + "/goods/getPlatformGoodsDetail" //商品资料库详情2.3.6.8新增字段

// 基础设置
// 银行管理
export const bankSelectBank = mgEngineURL + "/Bank/selectBank" //获取银行列表
export const bankAddBank = mgEngineURL + "/Bank/addBank" //新增银行信息
export const bankUpdateBank = mgEngineURL + "/Bank/updateBank" //编辑银行信息
export const bankDisabledBank = mgEngineURL + "/Bank/disabledBank" //启用/禁用银行
export const bankDeleteBank = mgEngineURL + "/Bank/deleteBank" //删除
// 默认店铺配置
export const bannerSelectBanner = mgEngineURL + "/banner/selectBanner" //根据供应商,团队,业务员获取Banne图
export const bannerInsertBanner = mgEngineURL + "/banner/insertBanner" //新增Banne图
export const bannerUpdateBanner = mgEngineURL + "/banner/updateBanner" //更新Banne图
export const bannerDeleteBanner = mgEngineURL + "/banner/deleteBanner" //更新Banne图

// 佣金提现审核
export const withdrawList = mgEngineURL + '/withdraw/examine/get' //佣金提现审核列表
export const withdrawDetailList = mgEngineURL + '/withdraw/examine/detail' //佣金提现明细
export const withdrawUpdateStatus = mgEngineURL + '/withdraw/examine/updateStatus' //审核通过或者驳回
export const queryWithdrawMoney = mgEngineURL + '/withdraw/examine/queryWithdrawMoney' //查询列表佣金总金额
export const withdListExport = mgEngineURL + '/withdraw/examine/export' //导出列表
// 商品审核
export const goodsBusinessDetail = mgEngineURL + "/goods/businessDetail" //商品审核详情
export const goodsHasGoodsByBarCode = mgEngineURL + "/goods/hasGoodsByBarCode" //通过69码查询平台商品是否存在
export const goodsCheck = mgEngineURL + "/goods/check" //商品资料库检测商品是否存在
// export const goodsAudit = mgEngineURL + "/goods/audit" //商品审核

// 用户中心
export const usergetPayType = mgEngineURL + "/user/getPayType" //获取支付列表
export const userreplacePayType = mgEngineURL + "/user/replacePayType" //更改支付   


// 结算中心管理
// 分佣统计
export const commissionStatistics = mgEngineURL + "/commission/statistics" //分佣统计列表
export const commissionClient = mgEngineURL + "/commission/client" //累计客户列表
export const commissionSalesman = mgEngineURL + "/commission/salesman" //业务员明细列表
// 图片上传
export const uploadFileRequest = (params) => {
  console.log(params)
  return axios({
    method: 'post',
    url: javaUploadUrl,
    data: params,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

// 查询业务员佣金提现配置
export const queryWithdrawConfig = mgEngineURL + "/pub/withdrawConfig/queryWithdrawConfig"
//修改提交业务员佣金提现配置
export const saveWithdrawConfig = mgEngineURL + "/pub/withdrawConfig/saveWithdrawConfig"
//ERP经营范围手动推送
export const businessscope = mgEngineURL + "/erp/businessscope"
//ERP经营范围手动推送
export const erpCodeMatch = userURL + "/erpCodeMatch/syncUser"


//对账管理
export const billList = mgEngineURL + "/pub/withdrawConfig/billList"; //账单列表
export const addBillInfo = mgEngineURL + "/pub/withdrawConfig/addBillInfo"; //添加付款
export const collectionInformation = mgEngineURL + "/pub/withdrawConfig/collectionInformation"; //账单收款信息
export const billDetailsList = mgEngineURL + "/pub/withdrawConfig/billDetailsList"; //收款明细列表
export const billAmount = mgEngineURL + "/pub/withdrawConfig/billAmount"; //收款明细金额相关
export const configet = mgEngineURL + "/supplier/corporate/config/get"; //获取供应商对公转账设置
