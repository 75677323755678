<template>
  <div class="Header">
    <div class="Header-box">
      <div class="navigation">
        <!-- <div class="i-box">
          <i @click="closeMenu" :class="isCloseMenu ? 'el-icon-s-fold' : 'el-icon-s-unfold'"></i>
        </div> -->
        <div class="nav-box">
          <!-- <div class="tags" @click="toRouter({ path: '/contentHome' })">
            首页
          </div>
          <div class="tags tags-1" v-for="(item, index) in skipRouterList" :key="index" @click="toRouter(item)">
            <template v-if="item.path != `/contentHome`">
              <span>/</span>{{ item.name }}
            </template>
          </div> -->
          药聚力平台管理系统
        </div>
      </div>
      <div class="common-btn">
        <div
          class="btn"
          @click="toRoter(`/UserList`)"
          v-if="BtnJurisdiction('/User/list')"
        >
          会员列表
        </div>
        <div
          class="btn"
          @click="toRoter(`/orderList`)"
          v-if="BtnJurisdiction('/order/list')"
        >
          订单列表
        </div>
        <div
          class="btn"
          @click="toRoter(`/goodsList`)"
          v-if="BtnJurisdiction('/goods/list')"
        >
          商品列表
        </div>
        <div class="box-quit">
          <el-dropdown>
            <span class="el-dropdown-link">
              {{ $cookie.get("newUserName") }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item divided>
                <div @click="amendPwd">修改密码</div>
              </el-dropdown-item>
              <el-dropdown-item divided>
                <div @click="downWire">退出</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="tags-box">
      <div class="tag-group">
        <el-tag
          type="info"
          :effect="$route.path == `/contentHome` ? `dark` : `plain`"
          size="medium"
          @click="toRouter({ path: `/contentHome` })"
          @contextmenu.prevent.native="rightClick('contentHome', $event)"
        >
          首页
        </el-tag>
        <el-tag
          v-for="(item, index) in tagsViewList"
          :key="index"
          @click="toRouter(item)"
          type="info"
          :effect="$route.path == item.path ? `dark` : `plain`"
          size="medium"
          @close="delTagsView(item)"
          @contextmenu.prevent.native="rightClick(item, $event)"
          closable
        >
          {{ item.name }}
        </el-tag>
      </div>
    </div>
    <!-- 修改密码弹窗 -->
    <el-dialog
      title="修改密码"
      :visible.sync="passInfoViable"
      :modal="false"
      :close-on-click-modal="false"
      :show-close="false"
      width="30%"
    >
      <el-form
        :model="passdata"
        label-position="right"
        label-width="100px"
        :rules="passrules"
        ref="passform"
      >
        <el-form-item label="账号" required>
          <el-input
            v-model="username"
            clearable
            autocomplete="off"
            :disabled="true"
          />
        </el-form-item>
        <el-form-item label="原密码" required>
          <el-input
            v-model="passdata.old_password"
            clearable
            aauto-complete="off"
            show-password
            maxlength="18"
          />
        </el-form-item>
        <el-form-item label="新密码" required prop="new_password">
          <el-input
            v-model="passdata.new_password"
            clearable
            aauto-complete="off"
            show-password
            maxlength="18"
          />
        </el-form-item>
        <el-form-item label="确认密码" required prop="repeat_password">
          <el-input
            v-model="passdata.repeat_password"
            clearable
            auto-complete="off"
            show-password
            maxlength="18"
          />
        </el-form-item>
      </el-form>
      <div class="text-center">
        <el-button v-if="flag" @click="passInfoViable = false">取 消</el-button>
        <el-button type="primary" @click="submitpass">确 定</el-button>
      </div>
    </el-dialog>
    <div
      v-show="visible"
      class="right-operate"
      :style="{ top: top + 'px', left: left + 'px' }"
    >
      <div @click="refreshSelectedTag()">刷新数据</div>
      <div @click="closeOthersTags()">清除此页面外所有页面</div>
      <div @click="closeAllTags()">清除所有页面</div>
    </div>
  </div>
</template>

<script>
import BtnJurisdiction from "@/utils/jurisdictionbtn"; //按钮权限公共方法
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("users"); //vuex公共库
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const PoweIndex = createNamespacedHelpers("power"); //权限vuex公共库
export default {
  data() {
    const equalToPassword = (rule, value, callback) => {
      if (this.passdata.new_password !== value) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    };
    return {
      tagsPath: {},
      visible: false,
      top: 0,
      left: 0,
      username: this.$cookie.get("newUserName"),
      passInfoViable: false,
      passdata: {
        old_password: "", //原密码
        new_password: "", //新密码
        repeat_password: "", //确认密码
      },
      passrules: {
        old_password: [
          { required: true, trigger: "change", message: "请输入原密码" },
          { required: true, trigger: "blur", message: "请输入原密码" },
          {
            pattern:
              /([\da-zA-Z]+?[_]+?)|([_]+?[\da-zA-Z]+?)|(\d+?[a-zA-Z]+?)|([a-zA-Z]+?\d+?)/,
            message: "密码必须有数字、字母两种规则以上组成,长度不小于6",
          },
        ],
        new_password: [
          { required: true, trigger: "change", message: "请输入新密码" },
          { required: true, trigger: "blur", message: "请输入新密码" },
          {
            pattern:
              /([\da-zA-Z]+?[_]+?)|([_]+?[\da-zA-Z]+?)|(\d+?[a-zA-Z]+?)|([a-zA-Z]+?\d+?)/,
            message: "密码必须有数字、字母两种规则以上组成,长度不小于6",
          },
        ],
        repeat_password: [
          { required: true, trigger: "change", message: "请输入确认密码" },
          { required: true, trigger: "blur", message: "请输入确认密码" },
          {
            pattern:
              /([\da-zA-Z]+?[_]+?)|([_]+?[\da-zA-Z]+?)|(\d+?[a-zA-Z]+?)|([a-zA-Z]+?\d+?)/,
            message: "密码必须有数字、字母两种规则以上组成,长度不小于6",
          },
          { required: true, validator: equalToPassword, trigger: "blur" },
        ],
      },
      flag: true,
    };
  },
  watch: {
    visible(value) {
      if (value) {
        document.body.addEventListener("click", this.closevisible);
      } else {
        document.body.removeEventListener("click", this.closevisible);
      }
    },
    $route(to, from) {
      if (from.path == "/promptlyAudit" && to.path == "/goodsAudit") {
        this.detorouter(to, from);
      }
      if (from.path == "/approvedMemo" && to.path == "/goodsAudit") {
        this.detorouter(to, from);
      }
      if (from.path == "/usersMessage" && to.path == "/userAudit") {
        this.detorouter(to, from);
      }
      if (from.path == "/userdetails" && to.path == "/userAudit") {
        this.detorouter(to, from);
      }
      if (from.path == "/toexamine" && to.path == "/userAudit") {
        this.detorouter(to, from);
      }
      if (from.path == "/addGood" && to.path == "/goodsCheck") {
        this.detorouter(to, from);
      }
      if (from.path == "/permission" && to.path == "/RolaList") {
        this.detorouter(to, from);
      }
      if (from.path == "/GoodFiling" && to.path == "/goodsCheck") {
        this.detorouter(to, from);
      }
      // if (from.path == "/promptlyAudit" && to.path == "/goodsAudit") {
      //   this.detorouter(from);
      // }
    },
  },
  computed: {
    ...commonIndex.mapState(["isCloseMenu", "skipRouterList", "tagsViewList"]),
    ...PoweIndex.mapState(["Menu"]),
  },
  created() {},
  methods: {
    ...mapActions(["postresetPassword"]),
    // 按钮权限
    BtnJurisdiction,
    ...commonIndex.mapMutations([
      "cutMenuState",
      "deltelTagsView",
      "closeSeach",
      "emptyTages",
      "closeElseTages",
      "refreshData",
    ]),
    closeAllTags(view) {
      // console.log(this.tagsViewList)
      // this.$router.push('/contentHome');
      this.emptyTages();
      // this.$store.dispatch("tagsView/delAllViews").then(({ visitedViews }) => {
      //   if (this.affixTags.some(tag => tag.path === view.path)) {
      //     return;
      //   }
      //   this.toLastView(visitedViews, view);
      // });
    },
    closeOthersTags() {
      this.closeElseTages(this.tagsPath);
      // this.$router.push(this.tagsViewList);
      // this.$store
      //   .dispatch("tagsView/delOthersViews", this.tagsViewList)
      //   .then(() => {
      //     this.moveToCurrentTag();
      //   });
    },
    refreshSelectedTag(view) {
      this.refreshData(this.tagsPath);
      // this.$store.dispatch("tagsView/delCachedView", view)
      // this.$store.dispatch("tagsView/delCachedView", view).then(() => {
      //   const { fullPath } = view;
      //   this.$nextTick(() => {
      //     this.$router.replace({
      //       path: "/redirect" + fullPath
      //     });
      //   });
      // });
      // sessionStorage.clear();
    },
    closevisible() {
      this.tagsPath = "";
      this.visible = false;
    },
    rightClick(val, ev) {
      this.visible = true;
      this.tagsPath = val;
      this.top = ev.pageY;
      this.left = ev.pageX;
    },
    // 删除tagsview
    delTagsView(path) {
      let router = path.path.substr(1);
      this.closeSeach(router);
      this.deltelTagsView(path);
    },
    //路由跳转删除tagsview
    detorouter(to, from) {
      let path = {
        name: from.name,
        path: from.path,
        query: from.query,
      };
      let topath = {
        name: to.name,
        path: to.path,
        query: to.query,
      };
      this.delTagsView(path);
      this.toRouter(topath);
    },
    //   关闭菜单列表
    closeMenu() {
      this.cutMenuState();
    },
    //   前往首页
    toHome() {
      this.$router.push({ path: "/contentHome" });
    },
    // 退出登录
    downWire() {
      //console.log(213);
      sessionStorage.removeItem("TOKEN");
      this.$router.push({ path: "/" });
    },
    errormes(val) {
      this.$message({
        message: val,
        type: "warning",
      });
    },
    // 保存修改密码
    async submitpass() {
      let regex = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,32}$/; //必须包含字母数字正则
      if (!regex.test(this.passdata.old_password)) {
        this.errormes("原密码长度必须大于6小于32且必须同时包含字母、数字");
        return;
      }
      if (!regex.test(this.passdata.new_password)) {
        this.errormes("新密码长度必须大于6小于32且必须同时包含字母、数字");
        return;
      }
      if (this.passdata.new_password != this.passdata.repeat_password) {
        this.errormes("两次密码输入不一致");
        return;
      }
      let data = await this.postresetPassword(this.passdata);
      if (data.code == 200) {
        this.$message({
          message: "密码修改成功",
          type: "success",
        });
        this.passInfoViable = false;
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 修改密码
    amendPwd() {
      this.passInfoViable = true;
      // this.$router.push({ path: "/amendPwd" });
    },
    // 面包屑导航跳转
    toRouter(data) {
      this.$router.push({ path: data.path, query: data.query });
    },
    // roter
    toRoter(data) {
      this.$router.push({ path: data });
    },
  },
};
</script>
<style lang="scss" scoped>
.Header {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.Header-box {
  width: 100%;
  height: 60px;
  // border-bottom: 1px solid #252949;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navigation {
  display: flex;
  align-items: center;
  .i-box {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
    color: #252949;
  }
  .nav-box {
    margin-left: 10px;
    color: #00284d !important;
    font-weight: bold !important;
    cursor: pointer;
    display: flex;
    .tags {
      padding: 2px;
      font-size: 14px;
      color: #252949;
    }
    .tags-1 {
      margin-left: 2px span {
        margin-right: 2px;
        font-size: 12px;
      }
    }
  }
}
.common-btn {
  display: flex;
  .btn {
    margin-left: 2%;
    width: 80px;
    height: 35px;
    border-radius: 5px;
    color: #fff;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    line-height: 35px;
    background: #00284d;
    cursor: pointer;
  }
  .box-quit {
    position: relative;
    width: 100px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .wz {
      font-size: 15px;
      display: flex;
    }
  }
}

.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}
::v-deep.el-tag {
  margin-right: 10px;
  height: 30px;
  cursor: pointer;
}
.tags-box {
  display: flex;
  align-items: center;
  width: 100%;
  height: 45px;
  background-color: #efefef;
  padding-left: 20px;
  padding-top: 10px;
}
.right-operate {
  position: fixed;
  font-size: 12px;
  // width: 160px;
  // height: 60px;
  background-color: #fff;
  border-radius: 6px;
  z-index: 999999;
  width: 147px;
  height: 90px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  div {
    height: 30px;
    // padding: 5px 0;
    padding-left: 13px;
    line-height: 30px;
    cursor: pointer;
    color: #31373d;
    font-size: 12px;
  }
  div:hover {
    background-color: #f5f6f7;
  }
}
::v-deep .el-dropdown-link {
  cursor: pointer;
  color: #00284d;
}
::v-deep.el-icon-arrow-down {
  font-size: 12px;
}
</style>
