import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
// 引入模块
import users from "./modules/User/users"//用户
import power from "./modules/Power/power"//权限模块
import commonIndex from "./modules/common/commonIndex"//公共数据模块
import Aptitude from "./modules/Aptitude/Aptitude" //资质管理模块
import Basics from "./modules/basics/basics"//基础设置模块
import userCenterList from './modules/userCenterList/userCenterList'  // 会员中心模块
import Audit from "./modules/audit/audit"//审核模块
import Order from "./modules/order/order"//订单
import afterSalesOrder from "./modules/order/afterSalesOrder"//售后订单
import Goods from "./modules/good/goods"//商品
import merchantCenterList from "./modules/merchantCenterList/merchantCenterList"  // 供应商模块
import settlement from "./modules/settlement/settlement"//结算
import userLoan from "./modules/userLoan/userLoan"//结算
import billList from "./modules/billList/billList"//结算
import routing from "./modules/routing/routing"//页面查询缓存
Vue.use(Vuex)

export default new Vuex.Store({
  // 配置模块
  modules: {
    users,
    power,//权限模块
    commonIndex,//公共逻辑模块
    Aptitude,//资质管理模块
    Basics,//基础设置模块
    userCenterList,// 会员中心模块
    Audit,//审核模块
    Order,//订单模块
    afterSalesOrder,//售后订单
    Goods,//商品
    merchantCenterList,// 供应商
    settlement,//结算
    userLoan,//金融管理
    billList,//对账单
    routing,//页面查询缓存
  },
  getters
})

