const state = {
    routData: {},
}

const mutations = {
    //添加
    ADD_QUERY: (state, data) => {
        Object.assign(state.routData, data)
    },
    //删除
    SET_QUERY: (state, key) => {
        delete state.routData[key]
    },
}

const actions = {
    querying({
        commit,
        state
    }, data) {
        var key = Object.keys(data)[0]
        if (state.routData[key]) {
            commit('SET_QUERY', key)
            commit('ADD_QUERY', data)
        } else {
            commit('ADD_QUERY', data)
        }
    },
    //删除
    empty({ commit, state }, key) {
        if (state.routData[key]) {
            commit('SET_QUERY', key)
        }
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
