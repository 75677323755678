import { GetAxios } from "../../../api/AxiosGet"; //get请求
import { PostAxios } from "../../../api/AxiosPost"; //post请求
import {
  getBillList,
  returnOrderList,
  returnOrderInfo,
  billExport,
  billRefundExport,
  billFreightExport,
  billChargedExport,
  billCommissionExport,
  settlementBillListList,
  settlementSubCommonList,
  SettlementBillListDetail,
  getCount,
  getPaymentTypeList
} from "../../../api/phpUrl"; //引入接口
import {
  commissionStatistics,
  commissionSalesman,
  commissionClient, 
  queryWithdrawConfig,
  saveWithdrawConfig
} from "../../../api/javaUrl"; //引入接口
import { Message } from "element-ui"; //引入elmentui提示框
export default {
  namespaced: true,
  // 集中数据
  state: {
    BillList: [], //台账列表
    BillTotal: 0, //台账总条数
    BillPage: 0, //台账当前页条数
    returnList: [], //退款单列表
    returnTotal: 0, //退款单总条数
    returnPage: 0, //退款单当前页条数
    returnInfo: {}, //退款单详情
  },
  // 处理state中的数据
  mutations: {
    // 设置供应商列表数据
    setBillList(state, data) {
      state.BillList = data.data;
      state.BillTotal = data.total;
      state.BillPage = data.per_page;
    },

    // 设置退款单列表数据
    setReturnList(state, data) {
      state.returnList = data.data;
      state.returnTotal = data.total;
      state.returnPage = data.per_page;
    },
  },
  // 发送异步请求
  actions: {
    // 获取供应商列表
    async getBillList(context, params) {
      return await PostAxios(getBillList, params);
    },
    // 获取退款单列表
    async returnOrderList(context, params) {
      return await PostAxios(returnOrderList, params);
    },
    // 获取退款单详情
    async returnOrderInfo(context, params) {
      return await GetAxios(returnOrderInfo, params);
    },
    // 导出货款
    async billExport(context, params) {
      return await GetAxios(billExport, params);
    },
    //导出退款
    async billRefundExport(context, params) {
      return await GetAxios(billRefundExport, params);
    },
    // 导出运费
    async billFreightExport(context, params) {
      return await GetAxios(billFreightExport, params);
    },
    // 导出手续费
    async billChargedExport(context, params) {
      return await GetAxios(billChargedExport, params);
    },
    // 导出佣金
    async billCommissionExport(context, params) {
      return await GetAxios(billCommissionExport, params);
    },
    // 分佣查询列表
    async getsettlementSubCommonList(context, params) {
      return await GetAxios(settlementSubCommonList, params);
    },
    // 账单查询列表
    async getsettlementBillListList(context, params) {
      return await GetAxios(settlementBillListList, params);
    },
    // 账单详情
    async getSettlementBillListDetail(context, params) {
      return await GetAxios(SettlementBillListDetail, params);
    },
    // 佣金总计
    async getCount(context, params) {
      return await GetAxios(getCount, params);
    },
    // 分佣统计
    async getCommissionStatistics(context, params) {
      return await GetAxios(commissionStatistics, params);
    },
    // 累计客户
    async getCommissionClient(context, params) {
      return await GetAxios(`${commissionClient}/${params.businessId}`, params);
    },
    // 业务员
    async getCommissionSalesman(context, params) {
      return await GetAxios(`${commissionSalesman}/${params.businessId}`, params);
    },
    //查询业务员佣金提现配置
    async getQueryWithdrawConfig(context, params) {
      return await GetAxios(queryWithdrawConfig, params);
    },
    // 新增/修改业务员佣金提现配置
    async saveWithdrawConfig(context, params) {
      return await PostAxios(saveWithdrawConfig, params);
    },
    // 前端获取支付方式列表
    async getPaymentTypeList(context, params) {
      return await GetAxios(getPaymentTypeList, params)
    },
  },
};
