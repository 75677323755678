  <template>
  <div>
    <div v-for="(item,index) in option" :key="index">
      <el-tooltip
        v-if="item.code==mess"
        class="item"
        effect="dark"
        :content="item.name"
        placement="top"
      >
        <div class="exceed">{{ item.name }}</div>
      </el-tooltip>
    </div>
    <div class="exceed"  v-if="!mess">-</div>
    <div class="exceed"  v-if="mess=='-'">-</div>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      type: Array,
      default: () => [],
    }, //商品说明书
    mess: {
      type: String,
      default: () => null,
    }, //说明书数据
  },
  data() {
    return {
      // content:''
    };
  },
};
</script>

<style lang="scss" scoped></style>
