import {
    GetAxios
} from "../../../api/AxiosGet"; //get请求
import {
    PostAxios
} from "../../../api/AxiosPost" //post请求
import {
    searchCriteriaGet, //获取下拉框数据
    afterSalesOrderListPost, //获取售后订单列表
    platforminterventionPost, //平台介入
    editGetOrPost, //编辑（get为获取
    getPaymentTypeList
} from "../../../api/phpUrl" //引入接口
import {
    Message
} from 'element-ui' //引入elmentui提示框
export default {
    namespaced: true,
    state: {
        statusValue: {}, //搜索条件状态
        afterSaleList: [], //售后单列表
        detailedData: {}, //详情数据
        infoData: {}, //售后信息
        logisticsIf: [], //物流信息
        afterSaleIdData: '', //售后id
        ofPageData: true, //显影判责页
        interventionStatus: [], //更新确认介入状态码
        // data.refund_status
    },
    mutations: {
        // 状态
        setStatusValue(state, data) {
            state.statusValue = data
        },
        // 列表
        setAfterSaleList(state, data) {
            data.data.map((v, i) => {
                if (v.type == 0) {
                    v.type = "退货"
                } else if (v.type == 1) {
                    v.type = "退款"
                } else if (v.type == 2) {
                    v.type = "冲红"
                } else if (v.type == 3) {
                    v.type = "退款退货"
                }
                if (v.state == 0) {
                    v.state = "待审核"
                } else if (v.state == 1) {
                    v.state = "已驳回"
                } else if (v.state == 2) {
                    v.state = "待发货"
                } else if (v.state == 3) {
                    v.state = "待收货"
                } else if (v.state == 5) {
                    v.state = "供应商已确定"
                } else if (v.state == 4) {
                    v.state = "已完成"
                } else if (v.state == -1) {
                    v.state = "已取消"
                }

                if (v.platform_state.status_ == -1) {
                    v.platform_state.status_ = "取消"
                } else if (v.platform_state.status_ == 0) {
                    v.platform_state.status_ = "等待平台介入"
                } else if (v.platform_state.status_ == 1) {
                    v.platform_state.status_ = "举证中"
                } else if (v.platform_state.status_ == 2) {
                    v.platform_state.status_ = "平台判责中"
                } else if (v.platform_state.status_ == 3) {
                    v.platform_state.status_ = "平台已判责"
                }

                if (v.pay_type == 0) {
                    v.pay_type = "转账"
                } else if (v.pay_type == 1) {
                    v.pay_type = "在线支付"
                } else if (v.pay_type == 2) {
                    v.pay_type = ""
                } else if (v.pay_type == 3) {
                    v.pay_type = "货到付款"
                } else if (v.pay_type == 4) {
                    v.pay_type = "账期"
                } else if (v.pay_type == 5) {
                    v.pay_type = "提付"
                } else if (v.pay_type == 6) {
                    v.pay_type = "业务员线下付款"
                } else if (v.pay_type == 40) {
                    v.pay_type = "药易购白条支付"
                } else if (v.pay_type == 50) {
                    v.pay_type = "药聚力白条支付"
                }
                
                if (v.refund_status == 0) {
                    v.refund_status = "未退款"
                } else if (v.refund_status == 1) {
                    v.refund_status = "已退款"
                } else if (v.refund_status == 2) {
                    v.refund_status = "退款中"
                }
                v.index = (data.current_page - 1) * data.per_page + i + 1
            })
            state.afterSaleList = data
        },
        //详情
        detailsList(state, data) {
            // 退款金额
            data.goods.map((item) => {
                item.refundAmount = (item.selling_price * Number(item.after_sale_num)).toFixed(2)
            })
            // 合计退款金额
            data.totalMoney = 0
            data.goods.map((item) => {
                data.totalMoney += Number(item.refundAmount)
            })

            if (data.type == 0) {
                data.type = "退货"
            } else if (data.type == 1) {
                data.type = "退款"
            } else if (data.type == 2) {
                data.type = "冲红"
            } else if (data.type == 3) {
                data.type = "退款退货"
            } else if (data.type == 4) {
                data.type = "供应商退款"
            }
            // -1:取消；0:等待平台介入；1:举证中；2:平台判责中；3:平台已判责
            if (data.status_ == 0) {
                data.status_ = "等待平台介入"
            } else if (data.status_ == 1) {
                data.status_ = "举证中"
            } else if (data.status_ == 2) {
                data.status_ = "平台判责中"
            } else if (data.status_ == 3) {
                data.status_ = "平台已判责"
            } else if (data.status_ == -1) {
                data.status_ = "取消"
            }


            if (data.refund_status == 0) {
                data.refund_status = "未退款"
            } else if (data.refund_status == 1) {
                data.refund_status = "已退款"
            } else if (data.refund_status == 2) {
                data.refund_status = "退款中"
            }

            if (data.state == 0) {
                data.state = "待审核"
            } else if (data.state == 1) {
                data.state = "已驳回"
            } else if (data.state == 2) {
                data.state = "待发货"
            } else if (data.state == 3) {
                data.state = "待收货"
            } else if (data.state == 4) {
                data.state = "已完成"
            } else if (data.state == 5) {
                data.state = "供应商已确认"
            } else if (data.state == -1) {
                data.state = "已取消"
            }
            state.detailedData = data

        },
        //售后信息
        getInfoData(state, data) {

            state.infoData = data
        },
        //物流信息
        getLogisticsIf(state, data) {
            state.logisticsIf = data
        },
        // 失败提示
        NoOpen(state, message) {
            Message.error(message)
        },
        // 成功提示
        open(state, message) {
            Message.success(message)
        },
        // 存售后id
        afterSaleIdM(state, data) {
            state.afterSaleIdData = data
        },
        //显影判责页
        ofPageM(state, data) {
            state.ofPageData = data
        },
        //更新确认介入状态码
        interventionStatusM(state, data) {
            state.afterSaleList.data.forEach((item, index) => {
                if (data[index].platform_state.status_ == -1) {
                    item.platform_state.status_ = "取消"
                } else if (data[index].platform_state.status_ == 0) {
                    item.platform_state.status_ = "等待平台介入"
                } else if (data[index].platform_state.status_ == 1) {
                    item.platform_state.status_ = "举证中"
                } else if (data[index].platform_state.status_ == 2) {
                    item.platform_state.status_ = "平台判责中"
                } else if (data[index].platform_state.status_ == 3) {
                    item.platform_state.status_ = "平台已判责"
                }
            })
        },
    },
    actions: {
        // 搜索条件状态
        async searchCriteriaData(context) {
            let res = await GetAxios(searchCriteriaGet)
            if (res.code == 200) {
                context.commit("setStatusValue", res.data)
            } else {
                this.$message.error('请联系管理员');
            }
        },

        // 确认介入
        async afterSalesOrderList(context, data) {
            let res = await PostAxios(afterSalesOrderListPost, data)
            context.commit("interventionStatusM", res.data.data)
        },
        // 平台介入
        async setPlatformintervention(context, data) {
            let res = await PostAxios(platforminterventionPost, data)
            if (res.code == 200) {
                context.commit("open", res.message)
            } else {
                context.commit("NoOpen", res.message)
            }
            return res
        },
        // 搜索
        async search(context, data) {
            let res = await PostAxios(afterSalesOrderListPost, data)
            if (res.code == 200) {
                context.commit("setAfterSaleList", res.data)
            } else {
                this.$message.error('请联系管理员');
            }
        },
        // 获取详情信息
        async setDetailsData(context, data) {
            let res = await GetAxios(editGetOrPost, data)
            //console.log(res, 'qqqq');
            if (res.code == 200) {
                let flow = res.data.speed.reverse()
                flow[3].son = flow[3].son.reverse()
                let flows = flow.filter(v => v.son.length > 0)
                // 售后信息

                context.commit("detailsList", res.data)
                context.commit("getInfoData", res.data.sale_info)
                // 物流信息
                context.commit("getLogisticsIf", flows)
            } else {
                this.$message.error('请联系管理员');
            }
        },
        // 处理
        async handle(context, data) {
            let res = await PostAxios(editGetOrPost, data)
            if (res.code == 200) {
                context.commit("open", res.message)
            } else {
                context.commit("NoOpen", res.message)
            }
            return res
        },
        // 售后id
        async afterSaleId(context, data) {
            context.commit("afterSaleIdM", data)
        },
        // 显影判责页
        async ofPage(context, data) {
            context.commit("ofPageM", data)
        }
    },
    getters: {
        powers(state) {
            return state.afterSaleList
        }
    }
}