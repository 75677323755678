<template>
  <div class="container">
    <div class="content1">
      <div class="message">
        <svg class="iconfont" aria-hidden="true">
          <use xlink:href="#SF-gongyingshangshuliang"></use>
        </svg>
        <div>
          <p>
            供应商数量
            <el-popover placement="top-start" title="供应商数量" width="400" trigger="hover"
              content="统计今日（自然日）截止到进入/刷新页面时，当前平台注册成功并通过审核的供应商数量">
              <el-button slot="reference"><i class="iconfont SF-wenhao miniicon"></i></el-button>
            </el-popover>
          </p>
          <p v-if="form.supplier" class="supplier" style="color: #06b7ae">
            {{ form.supplier.supplierquantity }}
          </p>
          <!-- <p>昨日：</p> -->
        </div>
      </div>
      <div class="message">
        <svg class="iconfont" aria-hidden="true">
          <use xlink:href="#SF-xinzhucegongyingshang"></use>
        </svg>
        <div>
          <p>
            新注册供应商
            <el-popover placement="top-start" title="新注册供应商" width="400" trigger="hover"
              content="统计今日（自然日）截止到进入/刷新页面时，当前新注册的供应商数量">
              <el-button slot="reference"><i class="iconfont SF-wenhao miniicon"></i></el-button>
            </el-popover>
          </p>
          <p v-if="form.supplier" class="supplier" style="color: #4471f6">
            {{ form.supplier.newsupplierquantity.today }}
          </p>
          <p v-if="form.supplier" class="yesterday">
            昨日：{{ form.supplier.newsupplierquantity.yesterday }}
          </p>
        </div>
      </div>
      <div class="message">
        <svg class="iconfont" aria-hidden="true">
          <use xlink:href="#SF-denglugongyingshang"></use>
        </svg>
        <div>
          <p>
            登录供应商
            <el-popover placement="top-start" title="登录供应商" width="400" trigger="hover"
              content="统计今日（自然日）截止到进入/刷新页面时，进行过登录操作供应商数量；若一家供应商登录多次，只按照一次计算">
              <el-button slot="reference"><i class="iconfont SF-wenhao miniicon"></i></el-button>
            </el-popover>
          </p>
          <p v-if="form.supplier" class="supplier" style="color: #ed8e35">
            {{ form.supplier.loginsupplier.today }}
          </p>
          <p v-if="form.supplier" class="yesterday">
            昨日：{{ form.supplier.loginsupplier.yesterday }}
          </p>
        </div>
      </div>
      <div class="message">
        <svg class="iconfont" aria-hidden="true">
          <use xlink:href="#SF-pingjunshiyongshichang"></use>
        </svg>
        <div>
          <p>
            平均使用时长
            <el-popover placement="top-start" title="平均使用时长" width="400" trigger="hover"
              content="统计今日（自然日）截止到进入/刷新页面时的数据，供应商登录至退出为用户的使用时长，所有供应商的使用时长相加除以供应商数量为平均使用时长，未登录的供应商使用时长为0">
              <el-button slot="reference"><i class="iconfont SF-wenhao miniicon"></i></el-button>
            </el-popover>
          </p>
          <p v-if="form.supplier" class="supplier" style="color: #68c445">
            {{ form.supplier.averageusagetime.today }}
          </p>
          <p v-if="form.supplier" class="yesterday">
            昨日：{{ form.supplier.averageusagetime.yesterday }}
          </p>
        </div>
      </div>
      <div class="message">
        <svg class="iconfont" aria-hidden="true">
          <use xlink:href="#SF-zhifudingdanliang"></use>
        </svg>
        <div>
          <p>
            支付订单量
            <el-popover placement="top-start" title="支付订单量" width="400" trigger="hover"
              content="统计今日（自然日）截止到进入/刷新页面时，所有支付成功的订单数量（按照子订单进行统计）">
              <el-button slot="reference"><i class="iconfont SF-wenhao miniicon"></i></el-button>
            </el-popover>
          </p>
          <p v-if="form.order" class="supplier" style="color: #de584c">
            {{ form.order.ordervo.today }}
          </p>
          <p v-if="form.order" class="yesterday">
            昨日：{{ form.order.ordervo.yesterday }}
          </p>
        </div>
      </div>

      <div class="message">
        <svg class="iconfont" aria-hidden="true">
          <use xlink:href="#SF-huiyuanshuliang"></use>
        </svg>
        <div>
          <p>
            会员数量
            <el-popover placement="top-start" title="会员数量" width="400" trigger="hover"
              content="统计今日（自然日）截止到进入/刷新页面时，当前平台注册成功并通过审核的会员数量">
              <el-button slot="reference"><i class="iconfont SF-wenhao miniicon"></i></el-button>
            </el-popover>
          </p>
          <p v-if="form.member" class="supplier" style="color: #4471F6">
            {{ form.member.total }}
          </p>
        </div>
      </div>
      <div class="message">
        <svg class="iconfont" aria-hidden="true">
          <use xlink:href="#SF-xinzhucehuiyuan"></use>
        </svg>
        <div>
          <p>
            新注册会员
            <el-popover placement="top-start" title="新注册会员" width="400" trigger="hover"
              content="统计今日（自然日）截止到进入/刷新页面时，当前新注册的会员数量">
              <el-button slot="reference"><i class="iconfont SF-wenhao miniicon"></i></el-button>
            </el-popover>
          </p>
          <p v-if="form.member" class="supplier" style="color: #DE584C ">
            {{ form.member.newregisteredmember.today }}
          </p>
          <p v-if="form.member" class="yesterday">
            昨日：{{ form.member.newregisteredmember.yesterday }}
          </p>
        </div>
      </div>
      <div class="message">
        <svg class="iconfont" aria-hidden="true">
          <use xlink:href="#SF-dengluhuiyuan"></use>
        </svg>
        <div>
          <p>
            登录会员
            <el-popover placement="top-start" title="登录会员" width="400" trigger="hover"
              content="统计今日（自然日）截止到进入/刷新页面时，进行过登录操作的会员数量；若一个会员登录多次，只按照一次计算">
              <el-button slot="reference" class="popover"><i class="iconfont SF-wenhao miniicon"></i></el-button>
            </el-popover>
          </p>
          <p v-if="form.member" class="supplier" style="color: #06B7AE ">
            {{ form.member.member.today }}
          </p>
          <p v-if="form.member" class="yesterday">
            昨日：{{ form.member.member.yesterday }}
          </p>
        </div>
      </div>
      <div class="message">
        <svg class="iconfont" aria-hidden="true">
          <use xlink:href="#SF-shangpinliulanliang"></use>
        </svg>
        <div>
          <p>
            商品浏览量
            <el-popover placement="top-start" title="商品浏览量" width="400" trigger="hover"
              content="统计今日（自然日）截止到进入/刷新页面时，所有会员的商品浏览量，会员每次点击进入某一个商品的详情页计一次，若一个会员点击同一商品N次浏览，则按照N次计算">
              <el-button slot="reference"><i class="iconfont SF-wenhao miniicon"></i></el-button>
            </el-popover>
          </p>
          <p v-if="form.commodity" class="supplier" style="color: #7742EA">
            {{ form.commodity.pageviews.today }}
          </p>
          <p v-if="form.commodity" class="yesterday">
            昨日：{{ form.commodity.pageviews.yesterday }}
          </p>
        </div>
      </div>
      <div class="message">
        <svg class="iconfont" aria-hidden="true">
          <use xlink:href="#SF-shouhoudingdanshuliang"></use>
        </svg>
        <div>
          <p>
            售后订单数量
            <el-popover placement="top-start" title="售后订单数量" width="400" trigger="hover"
              content="统计今日（自然日）截止到进入/刷新页面时，会员发起申请的售后订单数量">
              <el-button slot="reference"><i class="iconfont SF-wenhao miniicon"></i></el-button>
            </el-popover>
          </p>
          <p v-if="form.aftersales" class="supplier" style="color: #F1A55D ">
            {{ form.aftersales.aftersalestotal.today }}
          </p>
          <p v-if="form.aftersales" class="yesterday">
            昨日：{{ form.aftersales.aftersalestotal.yesterday }}
          </p>
        </div>
      </div>
    </div>

    <div class="content2">
      <div class="remind">
        <div class="title">重要提醒</div>
        <div class="kind">
          <div class="kind-title">售后相关</div>
          <div class="kind-detail">
            <div>
              待确认介入：<span v-if="form.aftersales">{{
                form.aftersales.pendingconfirmation
              }}</span>
            </div>
            <div>
              待判责售后订单：<span v-if="form.aftersales">{{
                form.aftersales.condemnation
              }}</span>
            </div>
          </div>
          <div class="kind-title">供应商相关</div>
          <div class="kind-detail">
            <div>证件过期：<span v-if="form.supplier">{{
                form.supplier.documents
              }}</span></div>
            <div>经营类别过期：<span v-if="form.supplier">{{
                form.supplier.operate
              }}</span></div>
          </div>
          <div class="kind-title">会员相关</div>
          <div class="kind-detail">
            <div>
              证件过期：<span v-if="form.member">{{
                form.member.documents
              }}</span>
            </div>
            <div style="min-width: 123px">
              经营类别过期：<span v-if="form.member">{{
                form.member.operate
              }}</span>
            </div>
          </div>
          <div class="kind-title">审核相关</div>
          <div class="kind-detail">
            <div>
              待审核供应商：<span v-if="form.audit">{{
                form.audit.auditsupplier
              }}</span>
            </div>
            <div>
              待审核商品：<span v-if="form.audit">{{
                form.audit.auditcommodity
              }}</span>
            </div>
            <div>
              待审核会员：<span v-if="form.audit">{{
                form.audit.auditmember
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <ve-histogram style="hieght: 300px" :extend="chartExtend" :data="chartData" :settings="chartSettings"
        :tooltip="tooltip" />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("users"); //vuex公共库
export default {
  data() {
    this.chartSettings = {
      labelMap: {
        BankReceiveAmount: "order",
        ActualAmout: "pay",
        InvoiceAmount: "cancel",
      },
      // yAxisName: ["百分比"],
    };
    this.chartExtend = {
      title: { text: "订单统计", left: "left", style: "fontSize:0.4rem" },
      // 图例设置  显示哪个颜色分别表示什么
      legend: {
        formatter: function (name) {
          // 用来格式化图例文本，支持字符串模板和回调函数两种形式。模板变量为图例名称 {name}
          if (name === "计划时间" || name === "实际时间") {
            return;
          }
          return name;
        },
        show: true,
        bottom: "bottom",
      },
      // 设置  计划时间和实际时间为 白色 #FFFFFF
      color: ["#FFFFFF", "#FFFFFF", "#E4E4E4", "#00CC33", "#FF7350", "#FF3300"],
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow", // type:"cross"
          crossStyle: {
            color: "#999",
          },
        },
      },
      // 每个柱子
      series(v) {
        // 颜色
        const colors = [
          "#06B7AE",
          "#6AD4CE ",
          "#B4E9E7",
          "#FF3300",
          "#FFFFFF",
          "#FFFFFF",
        ];
        // 设置柱子的样式
        v.forEach((i) => {
          // i.barWidth = 20
          // i.itemStyle = {
          //   color: colors[v.indexOf(i)],
          //   borderWidth: 0
          // }
          if (i.name === "计划时间" || i.name === "实际时间") {
            i.barWidth = 0;
          } else {
            i.barWidth = 15;
            i.itemStyle = {
              color: colors[v.indexOf(i)],
              borderWidth: 0,
            };
          }
          // i.label = {
          //   color: colors[i],
          //   show: false, // 每个柱子上的数据显示
          //   position: 'top'
          // }
        });
        return v;
      },
    };
    // 提示框内容
    this.tooltip = {
      trigger: "axis",
      position: function (point, params, dom, rect, size) {
        // 鼠标坐标和提示框位置的参考坐标系是：以外层div的左上角那一点为原点，x轴向右，y轴向下
        // 提示框位置
        var x = 0; // x坐标位置
        var y = 0; // y坐标位置
        // 当前鼠标位置
        var pointX = point[0];
        var pointY = point[1];
        // 外层div大小
        // var viewWidth = size.viewSize[0];
        // var viewHeight = size.viewSize[1];
        // 提示框大小
        var boxWidth = size.contentSize[0];
        var boxHeight = size.contentSize[1];
        // boxWidth > pointX 说明鼠标左边放不下提示框
        if (boxWidth > pointX) {
          x = 5;
        } else {
          // 左边放的下
          x = pointX - boxWidth;
        }
        // boxHeight > pointY 说明鼠标上边放不下提示框
        if (boxHeight > pointY) {
          y = 5;
        } else {
          // 上边放得下
          y = pointY - boxHeight;
        }
        return [x, y];
      },
      formatter: function (params, ticket, callback) {
        let htmlStr = "";
        for (let i = 0; i < params.length; i++) {
          const param = params[i];
          const xName = param.name; // x轴的名称
          const seriesName = param.seriesName; // 图例名称
          const value = param.value; // y轴值
          const color = param.color; // 图例颜色
          if (i === 0) {
            htmlStr += xName + "<br/>"; // x轴的名称
          }
          htmlStr += "<div>";
          // 文本颜色设置--2020-07-23(需要设置,请解注释下面一行)
          // htmlStr += '<span style="color:'+color+'">';
          // 圆点后面显示的文本
          if (seriesName === "计划时间" || seriesName === "实际时间") {
            htmlStr += seriesName + "：" + value;
          } else {
            // 为了保证和原来的效果一样，这里自己实现了一个点的效果
            htmlStr +=
              '<span style="margin-right:5px;display:inline-block;width:10px;height:10px;border-radius:5px;background-color:' +
              color +
              ';"></span>';
            htmlStr += seriesName + "：" + value;
          }
          // 文本颜色设置--2020-07-23(需要设置,请解注释下面一行)
          // htmlStr += '</span>';
          htmlStr += "</div>";
        }
        return htmlStr;
      },
    };
    return {
      chartData: {
        columns: [
          "mintime",
          "订单数量",
          "支付订单数",
          "取消订单数",
          // "计划时间",
          // "实际时间",
        ],
        // 自己定义的假数据
        rows: [],
      },
      form: {},
    };
  },
  created() {
    this.getdata();
  },
  methods: {
    ...mapActions(["getindex"]),
    async getdata() {
      let data = await this.getindex();
      this.form = data.data;
      this.chartData.rows = data.data.order.rowsdto;
      // 这里的中文是产品（彭强）喊改的，不要找我。修改于2022-6-14
      this.chartData.rows.forEach((v, i) => {
        v.mintime = v.mintime.split(' ')[1]
        let orderVal = v.order;
        let payVal = v.pay;
        let cancelVal = v.cancel;
        v.订单数量 = orderVal;
        v.支付订单数 = payVal;
        v.取消订单数 = cancelVal;
      });
    },
  },
  // beforeRouteEnter(to, from, next) {
  //   next((vm) => {
  //     // 获取后台返回的数据   对返回的天数进行计算
  //     vm.chartData.rows = vm.$route.query.data.map((item) => {
  //       const index1 = item.actualStartDate.indexOf(" ");
  //       const index2 = item.actualFinishDate.indexOf(" ");
  //       item.actualStartDate = item.actualStartDate.substring(0, index1);
  //       item.actualFinishDate = item.actualFinishDate.substring(0, index2);
  //       const temp = {
  //         阶段: item.processName,
  //         计划对比: 100,
  //         实际完成进度: null,
  //         已逾期: null,
  //         计划时间: item.expectedStartDate + "至" + item.expectedFinishDate,
  //         实际时间: item.actualStartDate + "至" + item.actualFinishDate,
  //       };
  //       // 计划天数
  //       const s1 = new Date(item.expectedStartDate.replace(/-/g, "/")); // 计划开始时间
  //       const f1 = new Date(item.expectedFinishDate.replace(/-/g, "/")); // 计划结束时间
  //       const time1 = f1.getTime() - s1.getTime(); // 计划天数
  //       const day1 = parseInt(time1 / (1000 * 60 * 60 * 24)); // 实际天数
  //       // 已完成
  //       if (item.status === "FINISH") {
  //         // 实际天数
  //         const s2 = new Date(item.actualStartDate.replace(/-/g, "/"));
  //         const f2 = new Date(item.actualFinishDate.replace(/-/g, "/")); // 计划结束时间
  //         const time2 = f2.getTime() - s2.getTime();
  //         const day2 = parseInt(time2 / (1000 * 60 * 60 * 24)); // 实际天数
  //         temp["实际完成进度"] = (day2 / day1).toFixed(4) * 100;
  //         temp["已逾期"] = 0;
  //       }
  //       // 未完成
  //       if (item.status === "NOT_FINISH") {
  //         // 实际天数
  //         const s2 = new Date(item.actualStartDate.replace(/-/g, "/"));
  //         const f2 = new Date(); // 当前日期：2017-04-24
  //         const time2 = f2.getTime() - s2.getTime();
  //         const day2 = parseInt(time2 / (1000 * 60 * 60 * 24)); // 实际天数
  //         temp["实际完成进度"] = (day2 / day1).toFixed(4) * 100;
  //         temp["已逾期"] = 0;
  //       }
  //       return temp;
  //     });
  //   });
  // },
};
</script>

<style lang="scss" scoped>
.content1 {
  background-color: #fff;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(5, 20%);
  margin-bottom: 20px;
  padding: 10px 20px;
  border-radius: 18px;
  .el-button {
    padding: 0;
  }
  .message {
    display: flex;
    line-height: 22px;
    padding: 10px 0;
    align-items: center;  
    .iconfont {
      width: 50px;
      height: 50px;
      margin-right: 10px; 
    }
    .miniicon {
      font-size: 12px;
      color: #0dbc79;
    }
    .supplier {
      font-size: 40px;
      font-weight: bold;
      padding: 10px 0;
    }
    .yesterday {
      font-size: 12px;
    }
  }
  button {
    border: none;
  }
}
.content2 {
  display: flex;
  > div {
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 18px;
  }
  .ve-histogram {
    flex: 1;
    height: 440px !important;
  }
  .remind {
    width: 380px !important;
    margin-right: 20px;
    .title {
      font-weight: 600;
      line-height: 24px;
      font-size: 20px;
    }
    .kind {
      padding-left: 20px;
    }
    .kind-title {
      font-weight: bold;
      padding-top: 15px;
      font-size: 12px;
      color: #06b7ae;
    }
    .kind-detail {
      display: grid;
      font-size: 12px;
      color: rgba($color: #000000, $alpha: 0.8);
      grid-template-columns: repeat(3, 33%);
      div {
        padding-top: 10px;
      }
      span {
        // color: aquamarine;
      }
    }
  }
}
</style>
