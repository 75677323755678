import { GetAxios } from "../../../api/AxiosGet"; //get请求
import { PostAxios } from "../../../api/AxiosPost"; //post请求
// manufacturerinfo
import {
  goodsAuditbusinessDetail, //商品审核详情
  platformGoodsdetail, //商品资料库详情
  platformGoodsedit, //商品编辑信息
  goodsAuditaudit, //供应商审核
  goodsAudittimeLine, //商品审核时间线
  goodsAuditsaveTimeLine, //商品时间线
  platformGoodscheck, //商品资料库检测商品是否存在
  categorylist, //商品分类列表
  categoryadd, //商品分类新增
  categorycategory, //商品分类父级菜单筛选项
  categoryedit, //商品编辑
  goodsAuditeditMerchant, //商品编辑图片/名称/分佣/共享/审核
  // platformGoodslist, //商品资料库列表
  goodsInfolist, //云仓商品资料库
  platformGoodsexport, //到处资料库数据
  goodsInfodetail, //云仓商品库详情
  baseGoodslist, //兜底数据详情
  baseGoodsadd, //新增兜底数据
  baseGoodsdelete, //删除
  categorydelete, //删除分类
  categorycommission, //调整频台
  goodsInfoexport,
  unclassified, //导出未分类
  platformGoodsapplyEdit,
  platformGoodsEdit,
  transferGoods,
  getAllGoodsCount,
  getCategoryById,
  hasGoodsByBarCode,
} from "../../../api/phpUrl"; //引入接口
import {
  configSetDetail,
  configSetUpdate,
  syncBigDataSyncPicture,
  syncBigDataSyncInstruction,
  syncBigDataRun,
  syncBigDataProgress,
  goodsList,
  goodsDetail,
  goodsEdit,
  updateForbidTheSaleStatus,
  goodsGetCategoryById,
  goodsBusinessDetail,
  goodsHasGoodsByBarCode,
  goodsCheck,
  saveBaseInfo,
  saveExtendsInfo,
  saveInstructions,
  newEdit,
  getPlatformGoodsDetail
  // goodsAudit
} from "../../../api/javaUrl"; //引入接口
import { goodsAudit } from "../../../api/phpUrl";
import { Message } from "element-ui"; //引入elmentui提示框
export default {
  namespaced: true,
  // 集中数据
  state: {
    AuditbusinessData: {
      content: {},
    }, //商品审核信息
  },
  // 处理state中的数据
  mutations: {
    setData(state, data) {
      data.auditContent = data.auditInfo.content;
      if (data.basicInfo.categoryArr && data.basicInfo.categoryArr.length > 0) {
        let categoryArr = [];
        data.basicInfo.categoryArr.forEach((item) => {
          let name = "";
          item.forEach((v, i) => {
            if (i == item.length || i == 0) {
              name = name + v;
            } else {
              name = name + ">" + v;
            }
          });
          categoryArr.push(name);
        });
        data.basicInfo.categoryArr = categoryArr;
      }
      data.basicInfo.articleNumber = data.articleNumber

      state.AuditbusinessData = data;
    },
  },
  // 发送异步请求
  actions: {
    // 商品审核详情
    async postgoodsAuditbusinessDetail(context, params) {
      let data = await PostAxios(goodsAuditbusinessDetail, params);
      if (data.data.content == undefined) {
        data.data.content = {};
      }
      context.commit("setData", data.data);
      return data;
    },
    // 商品资料库详情
    async getplatformGoodsdetail(context, params) {
      return await GetAxios(platformGoodsdetail, params);
    },
    // 编辑商品信息
    async postplatformGoodsedit(context, params) {
      return await PostAxios(platformGoodsedit, params);
    },
    // 新编辑商品信息
    async postplatformGoodsapplyEdit(context, params) {
      return await PostAxios(platformGoodsapplyEdit, params);
    },
    // 新编辑商品信息(new)
    async postplatformGoodsEdit(context, params) {
      return await PostAxios(platformGoodsEdit, params);
    },
    async postgoodsAuditaudit(context, params) {
      return await PostAxios(goodsAuditaudit, params);
    },
    // 商品审核时间线
    async getgoodsAudittimeLine(context, params) {
      return await GetAxios(goodsAudittimeLine, params);
    },
    // 修改时间线
    async postgoodsAuditsaveTimeLine(context, params) {
      return await PostAxios(goodsAuditsaveTimeLine, params);
    },
    // 商品资料库检测商品是否存在
    async postplatformGoodscheck(context, params) {
      return await PostAxios(platformGoodscheck, params);
    },
    // 获取商品分类列表
    async getcategorylist() {
      return await GetAxios(categorylist);
    },
    // 商品分类新增
    async postcategoryadd(context, params) {
      return await PostAxios(categoryadd, params);
    },
    async getcategorycategory(context, params) {
      return await GetAxios(categorycategory, params);
    },
    // 获取详情
    async getcategoryedit(context, params) {
      return await GetAxios(categoryedit, params);
    },
    // 编辑分类
    async postcategoryedit(context, params) {
      return await PostAxios(categoryedit, params);
    },
    // 商品编辑图片名称
    async postgoodsAuditeditMerchant(context, params) {
      return await PostAxios(goodsAuditeditMerchant, params);
    },
    // async getplatformGoodslist(context, params) {
    //   return await GetAxios(platformGoodslist, params);
    // },
    // 云仓商品资料库
    async getgoodsInfolist(context, params) {
      return await GetAxios(goodsInfolist, params);
    },
    // 导出云仓
    async getgoodsInfoexport(context, params) {
      // return
      let data = await GetAxios(goodsInfoexport);
      if (data.code == 200) {
        window.open(`${data.data.url}`);
      } else {
        Message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 导出
    async getplatformGoodsexport(context, params) {
      let data = await GetAxios(platformGoodsexport, params);
      if (data.code == 200) {
        window.open(`${data.data.url}`);
      } else {
        Message({
          message: data.message,
          type: "warning",
        });
      }
    },
    async getgoodsInfodetail(context, params) {
      return await GetAxios(goodsInfodetail, params);
    },
    async getGetgoodsInfodetail(context, params) {
      return await PostAxios(getAllGoodsCount, params);
    },

    // 兜底数据详情
    async getbaseGoodslist(context, params) {
      return await GetAxios(baseGoodslist, params);
    },
    // 新增
    async postbaseGoodsadd(context, params) {
      return await PostAxios(baseGoodsadd, params);
    },
    // 删除
    async postbaseGoodsdelete(context, params) {
      return await PostAxios(baseGoodsdelete, params);
    },
    // 删除商品分类
    async postcategorydelete(context, params) {
      return await PostAxios(categorydelete, params);
    },
    async posttransferGoods(context, params) {
      return await PostAxios(transferGoods, params);
    },
    async postGetCategoryById(context, params) {
      return await PostAxios(getCategoryById, params);
    },

    // 调整
    async postcategorycommission(context, params) {
      return await PostAxios(categorycommission, params);
    },
    // 导出未分类
    async getunclassified(context, params) {
      let data = await GetAxios(unclassified, params);
      if (data.code == 200) {
        window.open(`${data.data.url}`);
      } else {
        Message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 通过69码查询平台商品是否存在
    async gethasGoodsByBarCode(context, params) {
      return await GetAxios(hasGoodsByBarCode, params);
    },
    //商品资料库 资料库更新设置详情
    async getconfigSetDetail(context, params) {
      return await GetAxios(configSetDetail, params);
    },
    // 更新设置
    async postconfigSetUpdate(context, params) {
      return await PostAxios(configSetUpdate, params);
    },
    // 更新设置
    async postsyncBigDataSyncPicture(context, params) {
      return await PostAxios(syncBigDataSyncPicture + params);
    },
    // 更新设置
    async postsyncBigDataSyncInstruction(context, params) {
      return await PostAxios(syncBigDataSyncInstruction + params);
    },
    //商品资料库 更新商品信息 进度
    async getsyncBigDataProgress(context, params) {
      return await GetAxios(syncBigDataProgress, params);
    },
    // 商品资料库  更新商品信息 全部
    async postsyncBigDataRun(context, params) {
      return await PostAxios(syncBigDataRun, params);
    },
    // 商品资料库  更新商品信息 全部
    async postgoodsList(context, params) {
      return await PostAxios(goodsList, params);
    },
    async getgoodsDetail(context, params) {
      return await GetAxios(goodsDetail, params);
    },
    async postgoodsEdit(context, params) {
      return await PostAxios(goodsEdit, params);
    },
    async postupdateForbidTheSaleStatus(context, params) {
      return await PostAxios(updateForbidTheSaleStatus, params);
    },
    async postgoodsGetCategoryById(context, params) {
      return await PostAxios(goodsGetCategoryById, params);
    },
    async getgoodsBusinessDetail(context, params) {
      let data = await GetAxios(goodsBusinessDetail, params);
      // if (data.content.content == undefined) {
      //   data.data.content = {};
      // }
      context.commit("setData", data.content);
      return data;
    },
    async getgoodsHasGoodsByBarCode(context, params) {
      return await GetAxios(goodsHasGoodsByBarCode, params);
    },
    async postgoodsCheck(context, params) {
      return await PostAxios(goodsCheck, params);
    },
    async postgoodsAudit(context, params) {
      let supertubeId = sessionStorage.getItem("admin_id");
      params.supertubeId = supertubeId;
      return await PostAxios(goodsAudit, params);
    },
    async saveBaseInfo(context, params) {
      return await PostAxios(saveBaseInfo + '/' + params.operatorId, params);
    },
    async saveExtendsInfo(context, params) {
      return await PostAxios(saveExtendsInfo + '/' + params.goodsId, params);
    },
    async saveInstructions(context, params) {
      return await PostAxios(saveInstructions + '/' + params.contentObj.goodsId, params);
    },
    async newEdit(context, params) {
      return await PostAxios(newEdit + '/' + params.opType, params);
    },
    async getPlatformGoodsDetail(context, params) {
      return await GetAxios(getPlatformGoodsDetail, params);
    },
  },
};
