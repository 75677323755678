import axios from 'axios'
import {
  Message, MessageBox
} from 'element-ui'
import {
  getToken
} from '@/utils/auth'
import store from "@/store";
import router from '@/router'

const service = axios.create({
  baseURL: '',
  headers: {
    'Content-Type': 'application/json;',
  }
})
// 请求拦截器
service.interceptors.request.use(
  config => {
    config.headers['TOKEN'] = getToken()
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code == 200 || res.code == '000000') {
      return res
    } else {
      if (res.code == 90000) {
        Message({
          message: res.message || '服务器响应失败!!请联系管理员',
          type: 'error',
          duration: 5 * 1000
        })
        return res
      } else if (res.code == 70000) {
        if (store.getters.numbve) {
          store.dispatch("users/changenumbve", false);
          MessageBox.confirm(
            "登录状态已过期，您可以继续留在该页面，或者重新登录",
            "系统提示",
            {
              confirmButtonText: "重新登录",
              cancelButtonText: "取消",
              type: "warning"
            }
          ).then(() => {
            sessionStorage.removeItem("TOKEN");
            router.push({ path: "/" });
          });
        }
      } else if (!res.code) {
        return res
      } else {
        Message({
          message: res.message || '服务器响应失败!!请联系管理员',
          type: 'error',
          duration: 5 * 1000
        })
      }
    }
  },
  error => {
    Message({
      message: '服务器响应失败！！，请联系管理员',
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
